import CardCategory1 from "components/CardCategory1/CardCategory1";
import WidgetHeading1 from "components/WidgetHeading1/WidgetHeading1";
import { FC, useMemo, useState } from "react";
import axios from "axios";
const URL_API = process.env.REACT_APP_API;

export interface WidgetCategoriesProps {
  className?: string;
  articles?: any;
  categories?: any;
  arId?:any
}

const WidgetCategories: FC<WidgetCategoriesProps> = ({
  className = "bg-neutral-100 dark:bg-neutral-800",
  articles,
  arId
}) => {


  const [newPosts, setNewPosts] = useState<any>([]);
  
  // FET NEW RELATED ARTICLES
  useMemo(() => {
    axios.get(`${URL_API}articles?sort[0]=id%3Adesc&pagination[limit]=2&populate=*`)
      .then(res => res.data)
      .then(data => setNewPosts((prev:any) => {
        return [...prev, ...data.data]
      }));

      axios.get(`${URL_API}videos?sort[0]=id%3Adesc&pagination[limit]=1&populate=*`)
      .then(res => res.data)
      .then(data => setNewPosts((prev:any) => {
        return [...prev, ...data.data]
      }));

      axios.get(`${URL_API}ebooks?sort[0]=id%3Adesc&pagination[limit]=1&populate=*`)
      .then(res => res.data)
      .then(data => setNewPosts((prev:any) => {
        return [...prev, ...data.data]
      }));

      axios.get(`${URL_API}collection-audios?sort[0]=id%3Adesc&pagination[limit]=2&populate=*`)
      .then(res => res.data)
      .then(data => setNewPosts((prev:any) => {
        return [...prev, ...data.data]
      }));
  }, [])
  return (
    <>
      {newPosts.length !== 0 &&  <div
        className={`nc-WidgetCategories rounded-3xl  overflow-hidden ${className}`}
        data-nc-id="WidgetCategories"
      >
        <WidgetHeading1
          title="Nouvelles publications"
          viewAll={{ label: "", href: "/#" }}
        />
        <div className="flow-root">
          <div className="flex flex-col divide-y divide-neutral-200 dark:divide-neutral-700">
            {newPosts && newPosts.filter((item:any) => item.id !== arId ).map((category: any) => (
              <CardCategory1
                className="p-4 xl:p-5 hover:bg-neutral-200 dark:hover:bg-neutral-700"
                key={category.id}
                taxonomy={category}
                size="normal"
              />
            ))}
          </div>
        </div> 
      </div>}
    </>
    
  );
};

export default WidgetCategories;
