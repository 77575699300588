import { FC, useState } from "react";
import Input from "components/Input/Input";
import Label from "components/Label/Label";
import LayoutPage from "components/LayoutPage/LayoutPage";
import Textarea from "components/Textarea/Textarea";
import { Helmet } from "react-helmet";
import axios from "axios";
import { toast } from "react-toastify";
import InstagramGlide from "components/Instagram/InstagramGlide";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import emailjs from '@emailjs/browser'
const URL_API = process.env.REACT_APP_API;


export interface PageContactProps {
  className?: string;
}

const PageContact: FC<PageContactProps> = ({ className = "" }) => {
  const [name, setName] = useState<string>("");
  const [objet, setObjet] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const CLASSES = `nc-Button relative h-auto inline-flex items-center justify-center rounded-full transition-colors`;

  const sendFormHandler = (e: any) => {
    e.preventDefault();
    let formdata = new FormData(e.target);
      axios
      .post(`${URL_API}contacts`, {
        data: {
          nom: formdata.get("nom"),
          message: formdata.get("message"),
          email: formdata.get("email"),
          sujet: formdata.get("sujet"),
        },
      })
      .then(() => {
        emailjs.sendForm('service_5lfgtqa', 'template_mc6ewek', e.target, 'LFvDpR5-5cJOfocxX')
      .then((result) => {
        console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
        toast.success("Votre demande a étè bien envoyer!", {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setName("");
        setMessage("");
        setEmail("");
        setObjet("");
      })
      .catch((err) => {
        toast.error("error has been occurred!", {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  const nameChangeHandler = (e: any) => {
    setName(e.target.value);
  };
  const objetChangeHandler = (e: any) => {
    setObjet(e.target.value);
  };
  const emailChangeHandler = (e: any) => {
    setEmail(e.target.value);
  };
  const textChangeHandler = (e: any) => {
    setMessage(e.target.value);
  };

  return (
    <GoogleReCaptchaProvider reCaptchaKey="6LeTRTIfAAAAAGnZbH5UdjOtUl-GeP9cOPaXeHJh">
      <div className={`nc-PageContact ${className}`} data-nc-id="PageContact">
        <Helmet>
          <title>An-Nassiha || Nous contacter</title>
        </Helmet>
        <LayoutPage
          subHeading="Pour tout renseignement, vous pouvez nous contacter en remplissant le formulaire ci-dessous."
          headingEmoji=""
          heading="Nous contacter"
        >
          <div className="xl:w-1/2 lg:w-1/2 mx-auto w-full">
            <div>
              <form
                className="grid grid-cols-1 gap-6"
                onSubmit={sendFormHandler}
              >
                <label className="block">
                  <Label>Nom complet</Label>
                  <Input
                    name="nom"
                    placeholder="Nom complet"
                    type="text"
                    className="mt-1"
                    value={name}
                    required
                    onChange={nameChangeHandler}
                  />
                </label>
                <label className="block">
                  <Label>Objet</Label>
                  <Input
                    name="sujet"
                    placeholder="Objet"
                    type="text"
                    className="mt-1"
                    value={objet}
                    required
                    onChange={objetChangeHandler}
                  />
                </label>
                <label className="block">
                  <Label>Adresse E-mail</Label>
                  <Input
                    name="email"
                    type="email"
                    placeholder="example@example.com"
                    className="mt-1"
                    value={email}
                    required
                    onChange={emailChangeHandler}
                  />
                </label>
                <label className="block">
                  <Label>Message</Label>
                  <Textarea
                    name="message"
                    className="mt-1"
                    rows={6}
                    value={message}
                    required
                    minLength={10}
                    onChange={textChangeHandler}
                  />
                </label>
                <div></div>
                <button
                  className={`ttnc-ButtonPrimary disabled:bg-opacity-70 disabled:cursor-not-allowed bg-[#0c68b9] hover:bg-[#0A569A] text-neutral-50  py-4 ${CLASSES}`}
                  type="submit"
                >
                  Envoyer
                </button>
              </form>
            </div>
          </div>
        </LayoutPage>
        <InstagramGlide />
      </div>
    </GoogleReCaptchaProvider>
  );
};

export default PageContact;
