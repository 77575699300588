import { FC, memo } from "react";
import { Link } from "react-router-dom";

export interface PostCardMetaV2Props {
  className?: string;
  meta: any;
  hiddenAvatar?: boolean;
  size?: "large" | "normal";
}

const PostCardMetaV2: FC<PostCardMetaV2Props> = ({
  className = "leading-none",
  meta,
  hiddenAvatar = false,
  size = "normal",
}) => {
  let { titre: title } = meta.attributes;
  let author = {
    slug : meta?.attributes?.auteurs?.data?.length !== 0 ? meta?.attributes?.auteurs?.data[0].attributes.slug : '',
    displayName: meta?.attributes?.auteurs?.data?.length !== 0 ? meta?.attributes?.auteurs?.data[0].attributes.titre : '',
    href: "test"
  }
  
  return (
    <div
      className={`nc-PostCardMetaV2 inline-flex items-center flex-wrap text-neutral-800 dark:text-neutral-200 ${
        size === "normal" ? "text-xs" : "text-sm"
      } ${className}`}
      data-nc-id="PostCardMetaV2"
    >
      <div>
      <Link to={`/single/${meta.attributes.slug}`} className="relative flex items-center space-x-2">
        
          <h2
            className={`block font-semibold ${
              size === "normal" ? "text-base" : "text-lg"
            }`}
          >
            <span className="line-clamp-1">{title}</span>
          </h2>
      </Link>
          <div className=" mt-1.5">
            <Link to={`/author/${author.slug}`} className="block text-neutral-700 hover:text-black dark:text-neutral-300 dark:hover:text-white font-medium">
              {author.displayName}
            </Link>
          </div>
        </div>
      
    </div>
  );
};

export default memo(PostCardMetaV2);
