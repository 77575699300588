import { FC, Fragment, useEffect, useState } from "react";
import NcModal from "components/NcModal/NcModal";
import NcImage from "components/NcImage/NcImage";
import axios from "axios";
import { CheckIcon } from "@heroicons/react/solid";
const URL_API = "https://annasiha.herokuapp.com/api/";

export interface ModalCategoriesProps {
  categories?: any;
  catId?: any;
  onSendData?: any;
  filters?: any;
}
const ModalCategories: FC<ModalCategoriesProps> = ({ onSendData, filters }) => {
  const [categorie, setCategorie] = useState<any>();

  useEffect(() => {
    axios
      .get(`${URL_API}categories?populate=lienImage,articles,videos,audios`)
      .then((res) => res)
      .then((data) => setCategorie(data.data));
  }, []);

  const renderModalContent = () => {
    return (
      <div className="grid gap-6 sm:grid-cols-2 sm:py-2 md:gap-8 md:grid-cols-3 lg:grid-cols-4 xl:md:grid-cols-5">
        {categorie?.data &&
          categorie.data.map((cat: any, i: number) => {
            return (
              <Fragment key={i}>
                {
                  // (cat.attributes.articles.data.length>0 ||
                  //   cat.attributes.audios.data.length>0 ||
                  //   cat.attributes.videos.data.length>0) &&
                  <button
                    key={cat.id}
                    onClick={(e) => {
                      onSendData(cat.id, cat.attributes.titre);
                    }}
                    className={"nc-CardCategory1 flex items-center relative"}
                    data-nc-id="CardCategory1"
                  >
                    <NcImage
                      containerClassName={`flex-shrink-0 w-12 h-12 rounded-lg mr-4 overflow-hidden`}
                      src={cat?.attributes?.lienImage[0].lienImage}
                    />
                    <div>
                      <h2
                        className={`text-sm text-left nc-card-title text-neutral-900 dark:text-neutral-100 font-medium`}
                      >
                        {cat.attributes.titre}
                        {filters.includes(cat.attributes.titre) ? (
                          <span className="text-primary-700 absolute inset-y-0 right-0 flex items-center pl-3 dark:text-neutral-200">
                            <CheckIcon className="w-5 h-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </h2>
                    </div>
                  </button>
                }
              </Fragment>
            );
          })}
      </div>
    );
  };

  return (
    <div className="nc-ModalCategories">
      <NcModal
        triggerText={<span>Thèmes</span>}
        modalTitle="Thèmes"
        renderContent={renderModalContent}
      />
    </div>
  );
};

export default ModalCategories;
