import { FC } from 'react';

import { Link } from 'react-router-dom';
import imageholder from 'images/slide-pardefaut.jpg';

export interface CardCategory1Props {
  className?: string;
  taxonomy: any;
  size?: 'large' | 'normal';
}

const CardCategory1: FC<CardCategory1Props> = ({
  size = 'normal',
  taxonomy,
}) => {
  const { slug, titre } = taxonomy.attributes;

  return (
    <Link to={`/single/${slug}`} data-nc-id='CardCategory1'>
      <div className='w-full flex items-center gap-4  p-4'>
        <div className='link-image '>
          <img
            className={` ${
              size === 'large' ? 'w-20 h-20' : 'w-18 h-12'
            } rounded-lg  overflow-hidden`}
            src={
              taxonomy?.attributes?.lienImage?.length > 0
                ? taxonomy?.attributes?.lienImage[0].lienImage
                : imageholder
            }
            alt=''
          />
        </div>
        <div className='link-text'>
          <div>
            <h2
              className={`${
                size === 'large' ? 'text-lg' : 'text-base'
              } nc-card-title text-neutral-900 dark:text-neutral-100 font-semibold`}
            >
              {titre && titre}
            </h2>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default CardCategory1;
