import Glide from '@glidejs/glide'
import axios from 'axios';
import NextPrev from 'components/NextPrev/NextPrev';
import { useEffect, useState } from 'react';
import ncNanoId from 'utils/ncNanoId';
import styles from './InstagramGlide.module.css'
const URL_API = process.env.REACT_APP_API;


function InstagramGlide() {

    const[glide, setGlide] = useState<any>([])
    const UNIQUE_CLASS = ncNanoId("instagramSlider_");

    const perView = 4

    const MY_GLIDE = glide && glide.length !== 0 && new Glide(`.${UNIQUE_CLASS}`, {
    perView: perView,
    gap: 10,
    bound: true,
    breakpoints: {
      1080: {
        perView: 2,
        gap: 5,
      },
      640: {
        perView: 1,
      }
    },});

    useEffect(() => {
        axios.get(`${URL_API}publications-instagrams?populate=lienImage&sort[0]=sort%3Aasc`)
        .then(res => res.data)
        .then(data => setGlide(data.data));
    }, [])
    
    useEffect(() => {
        if (!MY_GLIDE) return;
        MY_GLIDE.mount();
    }, [MY_GLIDE]);

    return (
        <>
            {glide && glide.length !== 0 ?
                <div className='border-t border-neutral-200 dark:border-neutral-700'>
                    <div className={`${UNIQUE_CLASS} group relative z-10 w-full h-full container my-5`}>
                        <div className="glide__track h-full" data-glide-el="track">
                            <ul className="glide__slides h-full">
                                {glide.filter((_:any, i:any) => i < 8).map((item:any, index:any) => {
                                    return(
                                        <li className={`glide__slide  ${styles.instagramList}`} key={index}>
                                            {/* eslint-disable-next-line react/jsx-no-target-blank */}
                                            <a href={item.attributes.lien} target="_blank">
                                                {/* eslint-disable-next-line jsx-a11y/alt-text */}
                                                <img src={item.attributes.lienImage[0].lienImage} className={`${styles.instagramSlide}`} />
                                            </a>
                                        </li>
                                    )})}
                            </ul>
                        </div>
                        <div className='flex justify-center my-10'>
                            <NextPrev
                            btnClassName="w-12 h-12"
                            containerClassName="justify-start"
                            />
                        </div>  
                    </div>
                </div> : <div></div>
            }
        </>   
        
    
  )
}

export default InstagramGlide