import React from "react";
import { HelmetProvider } from "react-helmet-async";
import MyRouter from "routers/index";

function App() {
  return (
    <HelmetProvider>
      <div className="bg-white text-base dark:bg-neutral-900 text-neutral-900 dark:text-neutral-200">
        <MyRouter />
      </div>
    </HelmetProvider>
  );
}

export default App;
