import logo from "../../images/logo-annassiha.png";
import logoDark from "../../images/logo-annassiha-dark.png";
import { CustomLink } from "data/types";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import Input from "components/Input/Input";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import './Footer.css'
import { useAppSelector } from "app/hooks";
import { selectDarkmodeState } from "app/darkmode/darkmode";
const URL_API = process.env.REACT_APP_API;

export interface WidgetFooterMenu {
  id: string;
  title: string;
  menus: CustomLink[];
}

const Footer: React.FC = () => {

  const darkmodeIsEnnable = useAppSelector(selectDarkmodeState);
  const [email, setEmail] = useState('');

const sendEmail =  (e:any) => {
  e.preventDefault();
  let formdata = new FormData(e.target);
  axios.post(`${URL_API}news-letters`,{
    data: {
      email: formdata.get("email")
    }
  }).then(() => {
    toast.success('Vous vous êtes maintenant inscrit à notre newsletter', {
      position: "top-right",
      autoClose: 4000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    setEmail('');
  }).catch(() => {
    toast.error('Vous vous êtes déjà inscris à notre newsletter!', {
      position: "top-right",
      autoClose: 4000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  })
}


  return (
    <div className="nc-Footer relative py-10 border-t border-neutral-200 dark:border-neutral-700">
      <div className="container display_grid">
          <Link to='/' className="self-center">
            <img src={!darkmodeIsEnnable ? logo : logoDark} alt="an-nassiha" className="w-1/2 logo"/>
          </Link>
        <div className="flex flex-col gap-6 ">
          <h2 className="font-semibold text-neutral-700 dark:text-neutral-200">Info</h2>
          <div className="flex flex-col gap-2">
            <Link to={"/archive-author"} className="text-neutral-6000 dark:text-neutral-300 hover:text-black dark:hover:text-white">
              Auteurs
            </Link>
            <Link to={"/themes"} className="text-neutral-6000 dark:text-neutral-300 hover:text-black dark:hover:text-white">
              Thèmes
            </Link>
            <Link to={"/about"} className="text-neutral-6000 dark:text-neutral-300 hover:text-black dark:hover:text-white">
              Qui sommes-nous?
            </Link>
            <Link to={"/contact"} className="text-neutral-6000 dark:text-neutral-300 hover:text-black dark:hover:text-white">
              Nous contacter
            </Link>
          </div>
          
        </div>
        <div className="flex flex-col gap-6 columns">
          <h2 className="font-semibold text-neutral-700 dark:text-neutral-200">Nous Suivre</h2>
          <form className="col-span-3 relative flex h-12 gap-3 items-center" onSubmit={sendEmail}>
            <Input placeholder="Votre E-mail" value={email} onChange={(e) => setEmail(e.target.value)} name="email" type={"email"} required/>
            <button type="submit" className="absolute right-4"><i className="fas fa-arrow-right fa-2x"></i></button>
          </form>
          <div className="flex mt-2 socials">
            {/* eslint-disable-next-line react/jsx-no-target-blank */}
            <a
            target="_blank"
              href={"https://twitter.com/An_Nassiha"}
              className="flex items-center text-2xl text-neutral-700 hover:text-black dark:text-neutral-300 dark:hover:text-white leading-none space-x-2 group"
            >
              <i className="lab la-twitter fa-xl"></i>
            </a>
            {/* eslint-disable-next-line react/jsx-no-target-blank */}
            <a
            target="_blank"
              href={"https://www.instagram.com/an_nassiha/"}
              className="flex items-center text-2xl text-neutral-700 hover:text-black dark:text-neutral-300 dark:hover:text-white leading-none space-x-2 group"
            >
              <i className="lab la-instagram fa-xl"></i>
            </a>
            {/* eslint-disable-next-line react/jsx-no-target-blank */}
            <a
              href={"https://www.youtube.com/an_nassiha"}
              target="_blank"
              className="flex items-center text-2xl text-neutral-700 hover:text-black dark:text-neutral-300 dark:hover:text-white leading-none space-x-2 group"
            >
              <i className="lab la-youtube fa-xl"></i>
            </a>
            {/* eslint-disable-next-line react/jsx-no-target-blank */}
            <a
            target="_blank"
              href={"https://telegram.me/An_Nassiha"}
              className="flex items-center text-2xl text-neutral-700 hover:text-black dark:text-neutral-300 dark:hover:text-white leading-none space-x-2 group"
            >
              <i className="fa-brands fa-telegram fa-lg"></i>
            </a>
          </div>
        </div>
        <ToastContainer />
      </div>
    </div>
  );
};

export default Footer;
