import ButtonPlayMusicRunningContainer from 'containers/ButtonPlayMusicRunningContainer/ButtonPlayMusicRunningContainer';
import SingleTitle from 'containers/PageSingle/SingleTitle';
import SingleContent from 'containers/PageSingle/SingleContent';
import { Sidebar } from 'containers/PageSingle/Sidebar';
import Card15Podcast from 'components/Card15Podcast/Card15Podcast';
import ButtonPrimary from 'components/Button/ButtonPrimary';
import { useEffect, useState } from 'react';
import NcPlayIcon from 'components/NcPlayIcon/NcPlayIcon';
import isSafariBrowser from 'utils/isSafariBrowser';
import PODCAST_IMAGE from 'images/video.jpg';
import Glide from '@glidejs/glide';
import LoadingVideo from 'components/LoadingVideo/LoadingVideo';
import { MediaRunningState } from 'app/mediaRunning/mediaRunning';
import ncNanoId from 'utils/ncNanoId';
import iconPlaying from 'images/icon-playing.gif';
import { Link } from 'react-router-dom';
import NcImage from 'components/NcImage/NcImage';
import ReactPlayer from 'react-player';
import styles from './PageSingleGallery.module.css';
import { getData, getRelatedArticles } from './getData';
import Spinner from 'UI/Spinner';
import NextPrev from 'components/NextPrev/NextPrev';
import { toast } from 'react-toastify';
import BackgroundSection from 'components/BackgroundSection/BackgroundSection';
import Card10V2 from 'components/Card10/Card10V2';
import _ from 'lodash';
import './style.scss';

function PageSingleContent({ slug, SINGLE_DATA, getDataFromChild }: any) {
  const [isPlay, setIsPlay] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setData] = useState<any>();
  const [relatedPubs, setRelatedPubs] = useState<any>();
  const UNIQUE_CLASS = ncNanoId('sliderNewCategories_');
  const UNIQUE_CLASS_RELATED = ncNanoId('sliderRelatad_');
  const MY_GLIDE =
    isLoaded &&
    data &&
    SINGLE_DATA?.gallery !== '' &&
    new Glide(`.${UNIQUE_CLASS}`, {
      type: 'slider',
    });

  const MY_GLIDE_SECONDE =
    isLoaded &&
    relatedPubs &&
    new Glide(`.${UNIQUE_CLASS_RELATED}`, {
      type: 'slider',
      perView: 4,
      gap: 32,
      bound: true,
      breakpoints: {
        1080: {
          perView: 3,
          gap: 20,
        },
        640: {
          perView: 2,
          gap: 20,
        },
      },
    });

  useEffect(() => {
    setIsLoaded(false);
    Promise.all([
      getData(slug, 'Articles'),
      getData(slug, 'Videos'),
      getData(slug, 'collection-audios'),
      getData(slug, 'ebooks'),
    ]).then(function (values) {
      setData(() => {
        setIsLoaded(true);
        return values.filter((item: any) => item.length !== 0);
      });
    });
    // getRelatedArticles();
  }, [slug]);

  useEffect(() => {
    data &&
      getRelatedArticles(
        data[0][0]?.attributes?.categories?.data[0]?.attributes.slug
      )
        ?.then((res) => {
          if (res.length === 0) return;
          return {
            data: [
              ...res[0]?.attributes?.collection_audios.data,
              ...res[0]?.attributes?.videos.data,
              ...res[0]?.attributes?.ebooks.data,
              ...res[0]?.attributes?.articles.data,
            ],
          };
        })
        .then((data) => setRelatedPubs(data));
  }, [data]);

  useEffect(() => {
    isLoaded && data && getDataFromChild(data[0][0]);
  }, [data]);

  useEffect(() => {
    if (!MY_GLIDE || SINGLE_DATA?.gallery === '') return;
    data && MY_GLIDE.mount();
  }, [MY_GLIDE]);

  useEffect(() => {
    if (!MY_GLIDE_SECONDE) return;
    relatedPubs && MY_GLIDE_SECONDE.mount();
  }, [MY_GLIDE_SECONDE]);

  const renderIcon = (state?: MediaRunningState['state']) => {
    if (state === 'playing') {
      return <img className='w-7' src={iconPlaying} alt='' />;
    }

    if (state === 'loading') {
      return <LoadingVideo className='transform scale-75' />;
    }
    return (
      <svg className='w-11 h-11' fill='currentColor' viewBox='0 0 24 24'>
        <path
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1.5'
          d='M18.25 12L5.75 5.75V18.25L18.25 12Z'
        ></path>
      </svg>
    );
  };

  const renderButtonPlay = (
    isCurrentRunning: boolean,
    state?: MediaRunningState['state']
  ) => {
    let newState = state;
    if (!isCurrentRunning) {
      newState = null;
    }

    return (
      <div
        className={`aspect-w-1 aspect-h-1 rounded-full overflow-hidden shadow-2xl group cursor-pointer `}
      >
        <img
          className={`w-full h-full object-cover group-hover:scale-105 transform transition-transform nc-will-change-transform nc-animation-spin rounded-full ${
            newState === 'playing' ? 'playing' : ''
          }`}
          src={SINGLE_DATA.featuredImage}
          alt='audio'
        />

        <div className='bg-neutral-900 bg-blend-multiply bg-opacity-40 rounded-full'></div>
        <div className='flex items-center justify-center'>
          <div className='text-white bg-black bg-blend-multiply bg-opacity-30 w-20 h-20 border-2 border-neutral-300 rounded-full flex items-center justify-center '>
            {renderIcon(newState)}
          </div>
        </div>
      </div>
    );
  };

  const renderMainVideo = () => {
    return (
      <div
        className={`bg-neutral-800 rounded-3xl overflow-hidden border-4 border-white dark:border-neutral-700 shadow-2xl my-8 ${styles.aspectVideo}`}
      >
        {/* {isSafariBrowser() && PODCAST_IMAGE && !isPlay && (
          <div
            className='cursor-pointer rounded-[18px] overflow-hidden'
            onClick={() => setIsPlay(true)}
          >
            <NcImage
              src={SINGLE_DATA?.lienVideo?.videoUrl}
              containerClassName='inset-0'
              className=''
            />
            <div className='flex items-center justify-center'>
              <NcPlayIcon />
            </div>
          </div>
        )} */}
        <ReactPlayer
          url={SINGLE_DATA?.lienVideo?.videoUrl}
          className={styles.aspectVideo}
          width='100%'
          height='100%'
          style={{ borderRadius: 18, overflow: 'hidden' }}
          playing={isSafariBrowser() ? isPlay : true}
          controls
          light={isSafariBrowser() ? false : PODCAST_IMAGE}
          playIcon={<NcPlayIcon />}
        />
      </div>
    );
  };

  async function shareContent() {
    try {
      const response = await fetch(SINGLE_DATA?.gallery[0]);
      const blob = await response.blob();
      const filesArray = [
        new File([blob], `${SINGLE_DATA.title}.jpg`, {
          type: 'image/jpeg',
          lastModified: new Date().getTime(),
        }),
      ];
      const shareData = {
        files: filesArray,
        title: SINGLE_DATA.title,
        url: window.location.href,
      };
      return navigator.share(shareData);
    } catch (err) {
      toast.error("Le partage n'est pas supporté sur ce navigateur!", {
        position: 'top-right',
        autoClose: 4000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }

  // function share() {
  //     if (navigator.share) {
  //       navigator.share({
  //         title: SINGLE_DATA.title,
  //         url: window.location.href
  //       }).then(() => {
  //         console.log('Thanks for sharing!');
  //       })
  //       .catch(() => {
  //         toast.error("Try again!", {
  //             position: "top-right",
  //             autoClose: 4000,
  //             hideProgressBar: true,
  //             closeOnClick: true,
  //             pauseOnHover: true,
  //             draggable: true,
  //             progress: undefined,
  //         });
  //       });
  //     } else {
  //         toast.error("Le partage n'est pas supporté sur ce navigateur!", {
  //             position: "top-right",
  //             autoClose: 4000,
  //             hideProgressBar: true,
  //             closeOnClick: true,
  //             pauseOnHover: true,
  //             draggable: true,
  //             progress: undefined,
  //         });
  //     }
  //   }

  const renderShare = () => (
    <button
      className='h-8 w-8 bg-neutral-100 dark:bg-neutral-800 rounded-full flex items-center justify-center'
      onClick={shareContent}
    >
      <svg
        xmlns='http://www.w3.org/2000/svg'
        className='h-5 w-5'
        fill='none'
        viewBox='0 0 24 24'
        stroke='currentColor'
      >
        <path
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth={1.5}
          d='M8.684 13.342C8.886 12.938 9 12.482 9 12c0-.482-.114-.938-.316-1.342m0 2.684a3 3 0 110-2.684m0 2.684l6.632 3.316m-6.632-6l6.632-3.316m0 0a3 3 0 105.367-2.684 3 3 0 00-5.367 2.684zm0 9.316a3 3 0 105.368 2.684 3 3 0 00-5.368-2.684z'
        />
      </svg>
    </button>
  );

  return (
    <>
      {!data || !isLoaded ? (
        <Spinner />
      ) : (
        <>
          {SINGLE_DATA && SINGLE_DATA?.lienAudio?.audioUrl !== '' ? (
            <header className='relative container '>
              <div className='bg-white dark:bg-neutral-900 shadow-2xl px-5 py-7 md:p-11 rounded-2xl md:rounded-[40px] flex flex-col sm:flex-row items-center justify-center space-y-10 sm:space-y-0 sm:space-x-11'>
                <div className='w-1/2 sm:w-1/4 flex-shrink-0'>
                  <ButtonPlayMusicRunningContainer
                    renderChildren={renderButtonPlay}
                    post={SINGLE_DATA?.lienAudio}
                  />
                </div>
                <div className='flex flex-col space-y-5'>
                  <SingleTitle title={SINGLE_DATA?.title} />
                  <Link
                    to={`/author/${SINGLE_DATA?.author?.authorSlug}`}
                    className='text-neutral-700 md:!leading-[120%]  dark:text-neutral-400'
                  >
                    {SINGLE_DATA?.author?.authorName}
                  </Link>
                  <div>
                    {/* eslint-disable-next-line react/jsx-no-target-blank */}
                    <a
                      href={SINGLE_DATA?.lienAudio.audioUrl}
                      download
                      target={'_blank'}
                    >
                      <p className='rounded-2xl bg-gray-200 py-3 px-4 inline-block shadow-md hover:shadow-lg dark:bg-neutral-700'>
                        Télécharger{' '}
                        <i className='fa-solid fa-download fa-sm'></i>
                      </p>
                    </a>
                  </div>
                </div>
                {renderShare()}
              </div>
            </header>
          ) : (
            <header className='relative container '>
              <div className='bg-white dark:bg-neutral-900 shadow-2xl px-5 py-7 md:p-11 rounded-2xl md:rounded-[40px] flex flex-col sm:flex-row items-center justify-center space-y-10 sm:space-y-0 sm:space-x-11'>
                <div className='flex flex-col space-y-5'>
                  <SingleTitle title={SINGLE_DATA?.title} />
                  <Link
                    to={`/author/${SINGLE_DATA?.author?.authorSlug}`}
                    className='text-neutral-700 md:!leading-[120%]  dark:text-neutral-400'
                  >
                    {SINGLE_DATA?.author?.authorName}
                  </Link>
                </div>
                {renderShare()}
              </div>
            </header>
          )}
          {/* CONTENT */}
          <div className='container flex flex-col my-10 lg:flex-row'>
            <div className='w-full h-full lg:w-3/5 xl:w-2/3 xl:pr-20'>
              {/* IMAGE SLIDER */}
              {SINGLE_DATA?.gallery !== '' && (
                <div className={`${UNIQUE_CLASS} w-full mb-12`}>
                  <div className='glide__track relative' data-glide-el='track'>
                    <ul className='glide__slides w-full'>
                      {SINGLE_DATA?.gallery !== '' &&
                        SINGLE_DATA?.gallery.map((item: any, i: any) => (
                          <li className='glide__slide w-full' key={i}>
                            <img
                              src={item}
                              alt={slug}
                              className='object-fill w-full h-full rounded-2xl md:rounded-[40px]'
                            />
                          </li>
                        ))}
                    </ul>
                    {SINGLE_DATA?.gallery?.length > 1 && (
                      <div
                        className='glide__bullets'
                        data-glide-el='controls[nav]'
                      >
                        <>
                          {SINGLE_DATA?.gallery.map((_: any, i: any) => (
                            <button
                              className='glide__bullet'
                              data-glide-dir={`=${i}`}
                            ></button>
                          ))}
                        </>
                      </div>
                    )}
                    {SINGLE_DATA?.gallery?.length > 1 && (
                      <NextPrev containerClassName='flex justify-between px-6 my-2' />
                    )}
                  </div>
                </div>
              )}
              {SINGLE_DATA &&
                SINGLE_DATA?.audios !== '' &&
                SINGLE_DATA?.audios.length !== 0 &&
                _.orderBy(SINGLE_DATA?.audios, (item) => item.id).map(
                  (item: any, i: any) => (
                    <Card15Podcast
                      className='mt-4'
                      item={item}
                      author={SINGLE_DATA?.lienAudio?.author}
                      key={i}
                    />
                  )
                )}
              {SINGLE_DATA?.lienVideo?.videoUrl !== '' && renderMainVideo()}
              <SingleContent data={SINGLE_DATA} />
              {SINGLE_DATA?.source !== '' && (
                <span className='text-sm text-neutral-500'>Source : </span>
              )}
              {SINGLE_DATA?.source &&
                SINGLE_DATA.source.map((item: any, i: any) => (
                  // eslint-disable-next-line react/jsx-no-target-blank
                  <a
                    key={i}
                    target='_blank'
                    href={`//${item}`}
                    className='text-sm line-clamp-1 underline'
                  >
                    {item}
                  </a>
                ))}
              {SINGLE_DATA?.pdf && (
                <div className='mt-12 pdf_container text-center'>
                  {/* eslint-disable-next-line react/jsx-no-target-blank */}
                  <a
                    href={`${SINGLE_DATA?.pdf}`}
                    target='_blank'
                    className='pdf_link'
                  >
                    <ButtonPrimary>Télécharger au format pdf</ButtonPrimary>
                  </a>
                </div>
              )}
              {SINGLE_DATA?.lienZip && (
                <div className='mt-12 pdf_container text-center'>
                  {/* eslint-disable-next-line react/jsx-no-target-blank */}
                  <a
                    href={`${SINGLE_DATA?.lienZip}`}
                    target='_blank'
                    className='pdf_link'
                  >
                    <ButtonPrimary>Télécharger la série complète</ButtonPrimary>
                  </a>
                </div>
              )}
            </div>
            <div className='w-full mt-12 lg:mt-0 lg:w-2/5 lg:pl-10 xl:pl-0 xl:w-1/3'>
              <Sidebar arId={SINGLE_DATA?.id} />
            </div>
          </div>
          {relatedPubs && relatedPubs.length !== 0 && (
            <>
              <h2 className='text-center my-12 font-semibold text-2xl text-neutral-900 dark:text-neutral-100'>
                Autres suggestions
              </h2>
              <div className='container relative py-16 mb-16'>
                <BackgroundSection />
                <div>
                  <div className={`${UNIQUE_CLASS_RELATED} w-full`}>
                    <div className='glide__track' data-glide-el='track'>
                      <div className='glide__slides '>
                        {relatedPubs &&
                          _.sampleSize(relatedPubs?.data, 8)
                            .filter((item: any) => item.id !== SINGLE_DATA?.id)
                            .map((item: any, i: any) => (
                              <Card10V2
                                className='glide__slide'
                                key={i}
                                post={item}
                                showTitle={false}
                              />
                            ))}
                      </div>
                      {relatedPubs?.data.length > 1 && (
                        <NextPrev containerClassName='flex justify-between px-6 my-2' />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
}

export default PageSingleContent;
