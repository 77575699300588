import { FC, useEffect, useState } from 'react';
import Nav from 'components/Nav/Nav';
import NavItem from 'components/NavItem/NavItem';
import Avatar from 'components/Avatar/Avatar';
import HeadBackgroundCommon from 'components/HeadBackgroundCommon/HeadBackgroundCommon';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Spinner from 'UI/Spinner';
import ReactPaginate from 'react-paginate';
import Card10V2 from 'components/Card10/Card10V2';
import Input from 'components/Input/Input';
import InstagramGlide from 'components/Instagram/InstagramGlide';
import { checkCategoriesAuthors } from './chackAuthors';

export interface PageAuthorV2Props {
  className?: string;
}

const PageAuthorV2: FC<PageAuthorV2Props> = ({ className = '' }) => {
  const [tabActive, setTabActive] = useState<string>();
  const [author, setAuthor] = useState<any>();
  const [posts, setPosts] = useState<any>('');
  const [page, setPage] = useState(0);
  const { slug } = useParams<any>();
  const [searchInput, setSearchInput] = useState<any>('');
  const [isLoading, setIsLoading] = useState(true);
  const [isRerendringPagination, setRerendringPagination] = useState(false);
  const [inputHandler, setInputHandler] = useState<any>('');
  const [catTabs, setCatTabs] = useState<any>([]);
  const URL_API = process.env.REACT_APP_API;

  useEffect(() => {
    const navItems = checkCategoriesAuthors(slug)
      .then((data) => {
        return data.map((item: any) => {
          return [
            item?.attributes?.articles.data.length > 0 ? 'Articles' : '',
            item?.attributes?.collection_audios.data.length > 0 ? 'Audios' : '',
            item?.attributes?.ebooks.data.length > 0 ? 'Ebooks' : '',
            item?.attributes?.videos.data.length > 0 ? 'Vidéos' : '',
          ];
        });
      })
      .then((nav: any) => nav);
    const filtredItems = navItems.then((items: any) =>
      items[0].filter((item: any) => item !== '')
    );
    filtredItems.then((cat) => setCatTabs(cat));
  }, []);

  useEffect(() => {
    setTabActive(catTabs[0]);
  }, [catTabs]);

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(`${URL_API}auteurs?filters[slug][$eq]=${slug}&populate=*`)
      .then((res) => res.data)
      .then((data) => {
        setAuthor(data.data[0]);
        setIsLoading(false);
        setRerendringPagination(false);
      });
    switch (tabActive) {
      case 'Articles':
        // TODO: GETTING ALL ARTICLES OF AUTHOR
        axios
          .get(
            `${URL_API}articles?populate=*&filters[auteurs][slug][$in][0]=${slug}&filters[titre][$containsi]=${
              searchInput || inputHandler
            }&pagination[page]=${page}&pagination[pageSize]=12`
          )
          .then((res) => res.data)
          .then((data) => {
            setPosts(data);
            setIsLoading(false);
            setRerendringPagination(false);
          });
        break;

      case 'Vidéos':
        // TODO: GETTING ALL VIDEOS OF AUTHOR
        axios
          .get(
            `${URL_API}videos?filters[auteurs][slug][$in][0]=${slug}&filters[titre][$containsi]=${
              searchInput || inputHandler
            }&pagination[page]=${page}&pagination[pageSize]=12&populate=*`
          )
          .then((res) => res.data)
          .then((data) => {
            setPosts(data);
            setIsLoading(false);
            setRerendringPagination(false);
          });
        break;

      case 'Audios':
        // TODO: GETTING ALL ARTICLES OF AUTHOR
        axios
          .get(
            `${URL_API}collection-audios?populate=*&filters[auteurs][slug][$in][0]=${slug}&filters[titre][$containsi]=${
              searchInput || inputHandler
            }&pagination[page]=${page}&pagination[pageSize]=12`
          )
          .then((res) => res.data)
          .then((data) => {
            setPosts(data);
            setIsLoading(false);
            setRerendringPagination(false);
          });
        break;

      case 'Ebooks':
        // TODO: GETTING ALL VIDEOS OF AUTHOR
        axios
          .get(
            `${URL_API}ebooks?filters[auteurs][slug][$in][0]=${slug}&filters[titre][$containsi]=${
              searchInput || inputHandler
            }&pagination[page]=${page}&pagination[pageSize]=12&populate=*`
          )
          .then((res) => res.data)
          .then((data) => {
            setPosts(data);
            setIsLoading(false);
            setRerendringPagination(false);
          });
        break;

      default:
        break;
    }
  }, [tabActive, page, inputHandler, searchInput]);

  const titre = author ? author?.attributes : '';
  const imageUrl = author?.attributes?.lienImage
    ? author?.attributes?.lienImage[0].lienImage
    : '';
  let pageNumber = posts.meta ? posts.meta.pagination.pageCount : 1;

  const handleClickTab = (item: string) => {
    if (item === tabActive) {
      return;
    }
    setTabActive(item);
    setPage(0);
    setRerendringPagination(true);
  };

  const pageChangeHandler = (data: any) => {
    setPage(data.selected + 1);
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const renderPagination = () => {
    return (
      <ReactPaginate
        pageCount={pageNumber}
        onPageChange={pageChangeHandler}
        nextLabel={'>'}
        previousLabel={'<'}
        containerClassName={'flex items-center'}
        pageClassName={' flex items-center justify-center rounded-full w-8 h-8'}
        previousClassName={
          'flex items-center justify-center rounded-full w-8 h-8'
        }
        nextLinkClassName={
          ' flex items-center justify-center rounded-full w-8 h-8'
        }
        activeClassName={
          'bg-[#12629d] shadow-lg shadow-indigo-500/50 text-secondary-100 hover:text-netral-500'
        }
      />
    );
  };

  const onChangeHandler = (e: any) => {
    let timer = setTimeout(() => setSearchInput(e.target?.value), 2000);
    return () => clearTimeout(timer);
  };
  const searchHandler = () => {
    setInputHandler(searchInput);
  };

  return (
    <div className={`nc-PageAuthorV2  ${className}`} data-nc-id='PageAuthorV2'>
      {!author && <Spinner />}
      {author && (
        <>
          <Helmet>
            <title>Auteur || {titre?.titre ? titre.titre : ''} </title>
          </Helmet>
          <HeadBackgroundCommon className='h-24 2xl:h-28' />
          <div className='container'>
            <header className='max-w-xl mx-auto -mt-10 flex flex-col items-center justify-center text-center lg:-mt-14'>
              <Avatar
                containerClassName='ring-4 ring-white dark:ring-0 shadow-lg'
                imgUrl={imageUrl}
                sizeClass='w-20 h-20 text-lg lg:w-28 lg:h-28 lg:text-xl'
                radius='rounded-2xl'
              />
              <h2 className='block align-middle mt-4 font-semibold text-2xl text-neutral-900 lg:text-3xl dark:text-neutral-100'>
                {titre?.titre ? titre.titre : ''}
              </h2>
              {/* <SocialsList className="mt-3" /> */}
            </header>
          </div>
          {/* ====================== END HEADER ====================== */}
          <div className='container py-16 lg:py-28 space-y-16 lg:space-y-28'>
            <main>
              {/* TABS FILTER */}
              <div className='flex flex-col sm:items-center sm:justify-between sm:flex-row'>
                <Nav className='sm:space-x-2'>
                  {catTabs.map((item: any, index: any) => (
                    <NavItem
                      key={index}
                      isActive={tabActive === item}
                      onClick={() => handleClickTab(item)}
                    >
                      {item}
                    </NavItem>
                  ))}
                </Nav>
                <div className='py-6 relative'>
                  <Input
                    onChange={onChangeHandler}
                    className='w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 rounded-3xl'
                  />
                  <button
                    onClick={searchHandler}
                    className='absolute top-1/2 right-4 -translate-y-1/2'
                    type='submit'
                  >
                    <i className='las la-search'></i>
                  </button>
                </div>
                {/* 0eabbe */}
              </div>

              {/* LOOP ITEMS */}
              {isLoading ? (
                <Spinner />
              ) : (
                <div className='grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 md:gap-8 mt-8 lg:mt-10'>
                  {!posts && <Spinner />}
                  {tabActive === 'Articles' &&
                    posts &&
                    posts.data.map((post: any) => (
                      <Card10V2 key={post.id} post={post} />
                    ))}

                  {tabActive === 'Vidéos' &&
                    posts &&
                    posts.data.map((post: any) => (
                      <Card10V2 key={post.id} post={post} />
                    ))}

                  {tabActive === 'Audios' &&
                    posts &&
                    posts.data.map((post: any) => (
                      <Card10V2 key={post.id} post={post} />
                    ))}

                  {tabActive === 'Ebooks' &&
                    posts &&
                    posts.data.map((post: any) => (
                      <Card10V2 key={post.id} post={post} />
                    ))}
                </div>
              )}
              {/* PAGINATION */}
              <div className='flex flex-col mt-12 lg:mt-16 space-y-5 sm:space-y-0 sm:space-x-3 sm:flex-row sm:justify-between sm:items-center'>
                {/* <Pagination /> */}
                {!isRerendringPagination && renderPagination()}
              </div>
            </main>
          </div>
        </>
      )}
      <InstagramGlide />
    </div>
  );
};

export default PageAuthorV2;
