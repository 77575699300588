import axios from "axios"
const URL_API = process.env.REACT_APP_API;

export const  filter = (filter) => {
    switch(filter){
    case 'Récent':
        return `&sort[0]=id%3Adesc`
    case 'Ancien':
        return `&sort[0]=id%3Aasc`
    case 'A-Z':
        return `&sort[0]=titre%3Aasc`
    case 'Z-A':
        return `&sort[0]=titre%3Adesc`
    default:
        return `&sort[0]=id%3Aasc`
    }
  }

export const checkCategories = (theme) => {
    return axios.get(`${URL_API}categories?filters[slug][$in]=${theme}&populate=collection_audios,videos,articles,ebooks`)
    .then(res => res.data.data)
}