import './spinner.css'

function Spinner() {
  return (
      <div className='flex justify-center items-center'>
          <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
      </div>
  )
}

export default Spinner