import React, { useLayoutEffect, useState } from 'react';
import SectionLargeSlider from './SectionLargeSlider';
import { Helmet } from 'react-helmet';
import BackgroundSection from 'components/BackgroundSection/BackgroundSection';
import SectionSliderNewAuthors from 'components/SectionSliderNewAthors/SectionSliderNewAuthors';
import BgGlassmorphism from 'components/BgGlassmorphism/BgGlassmorphism';
import Heading from 'components/Heading/Heading';
import ButtonPrimary from 'components/Button/ButtonPrimary';
import axios from 'axios';
import { NavLink } from 'react-router-dom';
import Card10V2 from 'components/Card10/Card10V2';
import CardAuthorBox2 from 'components/CardAuthorBox2/CardAuthorBox2';
import InstagramGlide from 'components/Instagram/InstagramGlide';
const URL_API = 'https://annasiha.herokuapp.com/api/';

const PageHome: React.FC = () => {
  const [sliderArticles, setSliderArticles] = useState<any>();
  const [sliderAuteurs, setSliderAuteurs] = useState([]);
  const [categories, setCategories] = useState([]);
  const [articles, setArticles] = useState<any>([]);
  useLayoutEffect(() => {
    axios
      .get(
        `${URL_API}slider?populate=articles.lienImage,collection_audios.lienImage,videos.lienImage,ebooks.lienImage`
      )
      .then((res) => res.data)
      .then((data) =>
        setSliderArticles(() => {
          const sliderPost = [
            ...data?.data.attributes.articles.data,
            ...data?.data.attributes.collection_audios.data,
            ...data?.data.attributes.ebooks.data,
            ...data?.data.attributes.videos.data,
          ];
          return sliderPost;
        })
      );

    axios
      .get(`${URL_API}auteurs?populate=*&sort[0]=sort%3Aasc`)
      .then((res) => res.data)
      .then((data) => setSliderAuteurs(data.data));

    axios
      .get(`${URL_API}categories?populate=*&sort[0]=sort%3Aasc`)
      .then((res) => res.data)
      .then((data) => setCategories(data.data));

    // MODIFICATIONS

    axios
      .get(
        `${URL_API}articles?sort[0]=id%3Adesc&pagination[limit]=2&populate=*`
      )
      .then((res) => res.data)
      .then((data) =>
        setArticles((prev: any) => {
          return [...prev, ...data.data];
        })
      );

    axios
      .get(`${URL_API}videos?sort[0]=id%3Adesc&pagination[limit]=2&populate=*`)
      .then((res) => res.data)
      .then((data) =>
        setArticles((prev: any) => {
          return [...prev, ...data.data];
        })
      );

    axios
      .get(
        `${URL_API}collection-audios?sort[0]=id%3Adesc&pagination[limit]=2&populate=*`
      )
      .then((res) => res.data)
      .then((data) =>
        setArticles((prev: any) => {
          return [...prev, ...data.data];
        })
      );

    axios
      .get(`${URL_API}ebooks?sort[0]=id%3Adesc&pagination[limit]=2&populate=*`)
      .then((res) => res.data)
      .then((data) =>
        setArticles((prev: any) => {
          return [...prev, ...data.data];
        })
      );
  }, []);

  return (
    <div className='nc-PageHome relative'>
      <Helmet>
        <title>An-Nassiha</title>
      </Helmet>

      {/* ======== ALL SECTIONS ======== */}
      <div className='relative overflow-hidden'>
        {/* ======== BG GLASS ======== */}
        <BgGlassmorphism />
        {/* ======= START CONTAINER ============= */}
        <div className='container relative'>
          {/*  === HERO  === */}
          {/* FIXME: LOOP THROUGHT ARTICLES & LINK */}
          {sliderArticles && (
            <SectionLargeSlider
              className='pt-10 pb-16 md:py-16 lg:pb-28 lg:pt-24 '
              posts={sliderArticles}
            />
          )}

          <div className='relative py-16 mt-16'>
            <BackgroundSection />
            <Heading desc={''} isCenter>
              Nouvelles Publications
            </Heading>
            <ul className='grid xl:grid-cols-4 md:grid-cols-3 sm:1 gap-4'>
              {articles.map((item: any, index: number) => {
                return (
                  <li className='pb-12 xl:pb-16 glide__slide' key={index}>
                    <Card10V2 post={item} className='glide__slide' />
                  </li>
                );
              })}
            </ul>
          </div>
        </div>

        {/* === CATEGORIES === */}
        {/* DONNE: */}
        <div className={`container nc-SectionGridCategoryBox relative py-16`}>
          <Heading desc='' isCenter={true}>
            Thèmes
          </Heading>
          <div className='grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-5 sm:gap-6 md:gap-8'>
            {categories
              .filter((_, i) => i < 10)
              .map((item: any, i) => (
                <CardAuthorBox2
                  key={i}
                  item={item}
                  href={`/themes/${item.attributes.slug}`}
                />
              ))}
          </div>
          <div className='text-center mx-auto mt-10 md:mt-16'>
            <ButtonPrimary>
              <NavLink to='/themes'>Afficher tout</NavLink>
            </ButtonPrimary>
          </div>
        </div>

        {/* === LES AUTEURS  === */}
        {/* DONNE: */}
        <div className='container relative py-16 mb-16'>
          <BackgroundSection />
          <SectionSliderNewAuthors
            heading='Auteurs'
            subHeading=''
            authors={sliderAuteurs.filter((_, i) => i < 8)}
          />
        </div>
      </div>
      <InstagramGlide />
    </div>
  );
};

export default PageHome;
