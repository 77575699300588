import  { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import axios from "axios";
import ReactPaginate from "react-paginate";
import CardAuthorBox2 from "components/CardAuthorBox2/CardAuthorBox2";
import Spinner from "UI/Spinner";
import InstagramGlide from "components/Instagram/InstagramGlide";
const URL_API = process.env.REACT_APP_API;


const PageArchive: FC<any> = ({ className = "" }) => {
    const [page, setPage] = useState<number>(1)
    const [authors, setAuthors] = useState<any>();
    const [savants, setSavants] = useState<any>();
    const [search, setSearch] = useState<string>();
    const [searchBtn, setSearchBtn] = useState<string>();
    const [isLoadingSavants, setIsLoadingSavants] = useState(true);
    const [isLoadingAuteurs, setIsLoadingAuteurs] = useState(true);
    

    useEffect(() => {
      setIsLoadingSavants(true)
      setIsLoadingAuteurs(true)
        // Get Authors
        axios.get(`${URL_API}auteurs?pagination[page]=${page}&pagination[pageSize]=16&populate=*&filters[titre][$containsi]=${searchBtn ? searchBtn : ''}&filters[type][$eq]=auteur&sort[0]=sort%3Aasc`)
        .then(res => res)
        .then(data => {
          setAuthors(data.data)
          setIsLoadingAuteurs(false)
        });
        // Get SAVANTS
        axios.get(`${URL_API}auteurs?pagination[page]=${page}&pagination[pageSize]=16&populate=*&filters[titre][$containsi]=${searchBtn ? searchBtn : ''}&filters[type][$eq]=savant&sort[0]=sort%3Aasc`)
        .then(res => res)
        .then(data => {
          setSavants(data.data)
          setIsLoadingSavants(false)
        })
    },[page, searchBtn])

    const pageChangeHandler = (data:any) => {
      setPage(data.selected+1);
      setIsLoadingSavants(true)
      setIsLoadingAuteurs(true)
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }

  const renderPagination = () => {
    return (
      <ReactPaginate  
        forcePage={0}
        pageCount={savants?.meta.pagination.pageCount}
        onPageChange={pageChangeHandler}
        nextLabel = {'>'}
        previousLabel= {'<'}
        containerClassName={'flex items-center'}
        pageClassName={' flex items-center justify-center rounded-full w-8 h-8'}
        previousClassName = {'flex items-center justify-center rounded-full w-8 h-8'}
        nextLinkClassName = {' flex items-center justify-center rounded-full w-8 h-8'}
        activeClassName={'bg-[#12629d] shadow-lg shadow-indigo-500/50 text-secondary-100 hover:text-netral-500'}
        />
    )
  }
  const searchChangeHandler = (e: any) => {
    setSearch(e.target.value)
  }

  const searchHandler = () => {
      setSearchBtn(search);
      setIsLoadingSavants(true)
      setIsLoadingAuteurs(true)
  }
  
  return (
    <div
      className={`nc-PageArchive overflow-hidden ${className}`}
      data-nc-id="PageArchive"
    >
      <Helmet>
        <title>An-Nassiha || Auteurs</title>
      </Helmet>

        {!authors && <Spinner/>}
        {authors && <div className="container py-16 lg:py-28 space-y-16 lg:space-y-28">
        <div>
          <div className="flex flex-col sm:items-center sm:justify-between sm:flex-row">
            <div className="block my-4 border-b w-full border-neutral-100 sm:hidden"></div>
            <div className="w-full relative">
                <input type="text" onChange={searchChangeHandler} className="block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 rounded-3xl"/>
                <button onClick={searchHandler} className="absolute top-1/2 right-4 -translate-y-1/2"><i className="las la-search fa-lg"></i></button>
            </div>
          </div>

          {/* LOOP ITEMS */}
          {authors.data.length === 0 && savants.data.length === 0 && <h2 className="text-3xl md:text-2xl font-semibold text-center mt-12"> Aucun auteur n'est trouver avec le nom : {searchBtn}</h2>} 

          {isLoadingAuteurs ? <Spinner/> :
            authors.data.length !== 0  && <div className="mt-8">
            <h3 className="font-bold text-center text-2xl">Auteurs</h3>
            <div className="grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 md:gap-8 mt-8 lg:mt-10">
                {authors.data.map((post:any, i:number) => (
                  <CardAuthorBox2 item={post} key={i}/>
                ))}
              </div>
          </div>
          }
            {authors?.data.length !== 0 && savants?.data.length !== 0 && <div className="block m-auto w-1/2 my-12 border-b border-4  border-neutral-300 rounded-lg"></div>}
          {isLoadingSavants ? <Spinner/> :
            savants.data.length !== 0  &&  <div className="mt-8">
            <h3 className="font-bold text-center text-2xl">Savants</h3>
            <div className="grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 md:gap-8 mt-8 lg:mt-10">
                {savants.data.map((post:any, i:number) => (
                  <CardAuthorBox2 item={post} key={i}/>
                ))}
              </div>
          </div>
          }

          {/* PAGINATIONS */}
          <div className="flex flex-col mt-12 lg:mt-16 space-y-5 sm:space-y-0 sm:space-x-3 sm:flex-row sm:justify-between sm:items-center">
            {renderPagination()}
          </div>
        </div>

      </div>}
      <InstagramGlide />
    </div>
  );
};

export default PageArchive;
