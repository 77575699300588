import { FC, useState } from "react";
import { Link } from "react-router-dom";
import PostFeaturedMedia from "components/PostFeaturedMedia/PostFeaturedMedia";
import PostCardMetaV2 from "components/PostCardMeta/PostCardMetaV2";

export interface Card10V2Props {
  className?: string;
  post: any;
  type?: string;
  showTitle?: boolean;
}

const Card10V2: FC<Card10V2Props> = ({
  className = "h-full",
  post,
  type: postType,
  showTitle = true,
}) => {
  const [isHover, setIsHover] = useState(false);

  return (
    <div
      className={`nc-Card10V2 relative flex flex-col ${className}`}
      data-nc-id="Card10V2"
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      <div className="block group rounded-3xl flex-shrink-0 relative w-full aspect-w-16 aspect-h-9 sm:aspect-h-9 overflow-hidden ">
        <div>
          <PostFeaturedMedia item={post} isHover={isHover} type={postType} />
        </div>

        <Link
          to={`/single/${post.attributes.slug}`}
          className="absolute block w-3/4 inset-0 m-auto bg-neutral-900 bg-opacity-20 opacity-0 transition-opacity z-10"
        ></Link>
      </div>

      <div className="space-y-2.5 mt-4 px-4">
        {showTitle && <PostCardMetaV2 className="leading-none" meta={post} />}
      </div>
    </div>
  );
};

export default Card10V2;
