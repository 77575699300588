import { FC } from "react";
import WidgetCategories from "components/WidgetCategories/WidgetCategories";
import WidgetTags from "components/WidgetTags/WidgetTags";

export interface SidebarProps {
  className?: string;
  articles?: any;
  authorArticles?: any;
  tags?: any
  arId?:any
}

export const Sidebar: FC<SidebarProps> = ({ className = "space-y-6 ", arId}) => {
  return (
    <div className={`nc-SingleSidebar ${className}`}>
      <WidgetTags />
      <WidgetCategories arId={arId}/>
      {/* {authorArticles?.length !== 0 && <WidgetAuthors authorArticles={authorArticles} />} */}
      {/* <WidgetPosts posts={widgetPosts} /> */}
    </div>
  );
};
