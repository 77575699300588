import ButtonPrimary from 'components/Button/ButtonPrimary'
import Input from 'components/Input/Input'
import Label from 'components/Label/Label'
import Loading from './Loding'
import styles from './Admin.module.css'
import { useLayoutEffect, useState } from 'react'
import axios from 'axios'
import { toast } from 'react-toastify'
import { Helmet } from 'react-helmet-async'
import cookie from 'js-cookie';
import Spinner from 'UI/Spinner'
import Button from 'components/Button/Button'
import dFile from 'images/dFile.png'
import login from 'images/login.png'
const API_URL = process.env.REACT_APP_API;

function Admin() {

    const [passIsShown, setPassIsShown] = useState<boolean>(false);
    const [isLoading, setIsloading] = useState<boolean>(false);
    const [newsLetterMails, setNewsLetterMails] = useState<any>();
    const [isLoggedIn, setIsLoggedIn] = useState<any>(false);
    const [isLoadded, setIsLoaded] = useState<any>(false);

    useLayoutEffect(() => {
        if(cookie.get('TOKEN')){
            setIsLoaded(true)
            getNewsLetterMails (cookie.get('TOKEN'))
        }
    }, [])

    function getNewsLetterMails (token:any) {
        axios.get(
            `${API_URL}news-letters`,
            {
                headers: {
                    'Authorization': `Bearer ${token}`
                  }
            })
            .then((res) => {
                setNewsLetterMails(res.data.data)
                setIsLoggedIn(true);
                setIsLoaded(true);
                setIsloading(false);
                setPassIsShown(false)
            })
            .catch(() => cookie.remove('TOKEN'))
    }

    function submitHandler (e:any) {
        e.preventDefault()
        setIsloading(prev => !prev);
        const formData = new FormData(e.target);
        axios.post(`${API_URL}auth/local`,{
            identifier: formData.get("username"),
	        password: formData.get("password")
        }).then((res:any) => {
            if(res) return res.data
        })
        .then((data => {
            cookie.set('TOKEN', data.jwt, {
                expires: 1,
            });
            getNewsLetterMails(data.jwt)
        }))
        .catch(() => {
            toast.error("Mot de passe ou Utilisateur incorrect!", {
                position: "top-right",
                autoClose: 4000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            setIsloading(false)
        })
    }

    function downloadFile () {
        const element = document.createElement("a");
        const file = new Blob([`${newsLetterMails.map((item:any) => `${item.attributes.email}`)}`], {
            type: "text/plain"
        });
        element.href = URL.createObjectURL(file);
        element.download = "annassiha-newsletter.txt";
        document.body.appendChild(element);
        element.click();
        toast.success("Télèchargement en cours...!", {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    function logOut() {
        cookie.remove('TOKEN')
        setIsLoggedIn(false);
        setIsloading(false);
        setPassIsShown(false)
        setNewsLetterMails(null);
        toast.success("Vous êtes déconnecté", {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    setTimeout(() => setIsLoaded(true), 1000)

    return (
        <>
        <Helmet>
            <title>An-Nassiha - ADMIN</title>
        </Helmet>
        {!newsLetterMails && !isLoggedIn ? 
            <>
                {isLoadded ? <div className={'container my-16 '+ styles.container}>
                    <form className={`${styles.loginForm}`} onSubmit={submitHandler}>
                        <img src={login} alt="news letter" className={styles.imageLogin}/>
                        <div className={styles.label}>
                            <Label>Utilisateur</Label>
                            <div className={`${styles.input}`}>
                                <Input required minLength={5} name='username' type="text" placeholder='Utilisateur'/>
                                <i className="fa-solid fa-user"></i>
                            </div>
                        </div>
                        <div className={styles.label}>
                            <Label>Mot de passe</Label>
                            <div className={`${styles.input}`}>
                                <Input required minLength={6} type={passIsShown ? "text":"password"} name="password" placeholder='Mot de passe'/>
                                <i className="fa-solid fa-lock"></i>
                            </div>
                        </div>
                        <div className={styles.showPass}>
                            <Input type={'checkbox'} name='showPass' id='showPass'onChange={() => setPassIsShown(prev => !prev)}/>
                            <Label className='text-neutral-500'><label htmlFor="showPass">Afficher le mot de passe</label></Label>
                        </div>
                        <div className={styles.btn}>
                            <ButtonPrimary className={styles.button} type="submit" disabled={isLoading} >
                                {isLoading ? <Loading className={styles.loading}/> : <p>Connexion</p>}
                            </ButtonPrimary>
                        </div>
                    </form>
                </div> : <Spinner/>}
            </>
        : 
        <div className={`container ${styles.download}`}>
            <img src={dFile} alt="news letter" className={styles.image}/>
            <ButtonPrimary onClick={downloadFile} >Télécharger la liste des abonnés</ButtonPrimary>
            <Button onClick={logOut} className={styles.logout}>Déconnexion</Button>
        </div>}
    </>
  )
}

export default Admin