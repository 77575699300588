import { FC, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import NcImage from 'components/NcImage/NcImage';
import axios from 'axios';
import ReactPaginate from 'react-paginate';
import Heading from 'components/Heading/Heading';
import Spinner from 'UI/Spinner';
import CardAuthorBox2 from 'components/CardAuthorBox2/CardAuthorBox2';
import imageHolder from 'images/items.jpeg';
import InstagramGlide from 'components/Instagram/InstagramGlide';

const URL_API = 'https://annasiha.herokuapp.com/api/';

export interface PageArchiveProps {
  className?: string;
}

const PageArchive: FC<PageArchiveProps> = ({ className = '' }) => {
  const [categories, setCategories] = useState<any>();
  const [page, setPage] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(
        `${URL_API}categories?pagination[page]=${page}&pagination[pageSize]=12&populate=*&sort[0]=sort%3Aasc`
      )
      .then((res) => res.data)
      .then((data) => {
        setCategories(data);
        setIsLoading(false);
      });
  }, [page]);

  const pageChangeHandler = (data: any) => {
    setPage(data.selected + 1);
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const renderPagination = () => {
    return (
      <ReactPaginate
        forcePage={0}
        pageCount={categories.meta.pagination.pageCount}
        onPageChange={pageChangeHandler}
        nextLabel={'>'}
        previousLabel={'<'}
        containerClassName={'flex items-center'}
        pageClassName={' flex items-center justify-center rounded-full w-8 h-8'}
        previousClassName={
          'flex items-center justify-center rounded-full w-8 h-8'
        }
        nextLinkClassName={
          ' flex items-center justify-center rounded-full w-8 h-8'
        }
        activeClassName={
          'bg-[#12629d] shadow-lg shadow-indigo-500/50 text-secondary-100 hover:text-netral-500'
        }
      />
    );
  };

  return (
    <div
      className={`nc-PageArchive overflow-hidden ${className}`}
      data-nc-id='PageArchive'
    >
      <Helmet>
        <title>An-Nassiha || Thèmes</title>
      </Helmet>

      <div className='w-full px-2 xl:max-w-screen-2xl mx-auto'>
        <div className='rounded-3xl relative aspect-w-16 aspect-h-6 overflow-hidden'>
          <NcImage
            containerClassName='absolute inset-0'
            src={imageHolder}
            className='object-cover w-full h-full'
          />
          <div className='absolute inset-0 bg-black text-white bg-opacity-30 flex flex-col items-center justify-center'>
            <h2 className='inline-block align-middle text-5xl font-semibold md:text-7xl '>
              Thèmes
            </h2>
          </div>
        </div>
        <div className={`nc-SectionGridCategoryBox relative py-16`}>
          <div className='grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-5 sm:gap-6 md:gap-8'>
            {categories?.data && categories?.data.length < 1 && (
              <Heading desc='' isCenter={true}>
                There is no categories
              </Heading>
            )}
            {isLoading ? (
              <Spinner />
            ) : (
              categories?.data &&
              categories?.data.length > 0 &&
              categories.data.map((item: any, i: number) => (
                <CardAuthorBox2
                  key={i}
                  item={item}
                  href={`/themes/${item.attributes.slug}`}
                />
              ))
            )}
          </div>
          {/* PAGINATIONS */}
          <div className='flex flex-col mt-12 lg:mt-16 space-y-5 sm:space-y-0 sm:space-x-3 sm:flex-row sm:justify-between sm:items-center'>
            {categories?.data &&
              categories.meta.pagination.pageCount > 1 &&
              renderPagination()}
          </div>
        </div>
      </div>
      <InstagramGlide />
    </div>
  );
};

export default PageArchive;
