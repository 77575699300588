import  { FC, useEffect } from "react";
import parse from 'html-react-parser';

export interface SingleContentProps {
  data: any;
}

const SingleContent: FC<SingleContentProps> = ({ data }) => {

  useEffect(() => {
    
  }, []);

  return (
    <div className="nc-SingleContent space-y-10">
      {/* ENTRY CONTENT */}
      <div
        id="single-entry-content"
      >
        {/* THIS IS THE DEMP CONTENT */}
        {/* IF YOUR DATA IS JSON, YOU CAN USE render with html-react-parser (https://www.npmjs.com/package/html-react-parser) */}
        {/* <ReactMarkdown> 
          {content}
        </ReactMarkdown> */}

        {data?.content && parse(data.content)}
      </div>

      {/* TAGS */}
      <div className="max-w-screen-md mx-auto flex flex-wrap">
        {/* {tags && tags.map((item: any) => (
          <Tag hideCount key={item.id} tag={item} className="mr-2 mb-2" />
        ))} */}
      </div>
    </div>
  );
};

export default SingleContent;
