import SectionHero from "components/SectionHero/SectionHero";
import  { FC } from "react";
import { Helmet } from "react-helmet";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import InstagramGlide from "components/Instagram/InstagramGlide";

export interface PageAboutProps {
  className?: string;
}

const PageAbout: FC<PageAboutProps> = ({ className = "" }) => {
  return (
    <div
      className={`nc-PageAbout overflow-hidden relative ${className}`}
      data-nc-id="PageAbout"
    >
      <Helmet>
        <title>An-Nassiha || Qui somme nous ?</title>
      </Helmet>

      {/* ======== BG GLASS ======== */}
      <BgGlassmorphism />

      <div className="py-16 lg:py-28 space-y-16 lg:space-y-28">
        <SectionHero
          heading="QUI SOMMES-NOUS ?"
          btnText=""
          subHeading=""
        />
      </div>
      <InstagramGlide />
    </div>
  );
};

export default PageAbout;
