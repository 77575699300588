import { Popover, Transition } from "@headlessui/react";
import Input from "components/Input/Input";
import React, { Fragment } from "react";
import { useHistory } from "react-router-dom";
import styles from "./SearchDropdown.module.css";

const SearchDropdown = () => {
  const inputRef = React.createRef<HTMLInputElement>();
  const search = useHistory();

  const submitHandler = (e: any) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    search.push(`/search/${formData.get("search")}`);
  };

  console.log(window.screen.width);

  return (
    <React.Fragment>
      <Popover className="relative">
        {({ open }) => {
          if (open) {
            setTimeout(() => {
              inputRef.current?.focus();
            }, 100);
          }

          return (
            <>
              <Popover.Button className="text-2xl md:text-[28px] w-12 h-12 rounded-full text-neutral-700 dark:text-neutral-300 hover:bg-neutral-100 dark:hover:bg-neutral-800 focus:outline-none flex items-center justify-center">
                <i className="las la-search"></i>
              </Popover.Button>

              <Transition
                show={open}
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
              >
                <Popover.Panel
                  static
                  className={`absolute right-0 z-10 w-screen max-w-sm mt-3 inputSearchMobile ${styles.inputSearchMobile}`}
                >
                  <form onSubmit={submitHandler} className="relative">
                    <i className="las la-search absolute left-3 top-1/2 transform -translate-y-1/2 text-xl opacity-60"></i>
                    <Input
                      ref={inputRef}
                      name="search"
                      type="search"
                      placeholder={
                        window.screen.width > 450
                          ? `Tapez votre recherche et cliquez sur Entrer`
                          : `Tapez votre recherche`
                      }
                      className="pl-10"
                    />
                    <input type="submit" hidden value="" />
                  </form>
                </Popover.Panel>
              </Transition>
            </>
          );
        }}
      </Popover>
    </React.Fragment>
  );
};

export default SearchDropdown;
