import {
  NavItemType,
} from "components/Navigation/NavigationItem";
import ncNanoId from "utils/ncNanoId";


export const NAVIGATION_DEMO: NavItemType[] = [
  {
    id: ncNanoId(),
    href: "/",
    name: "Accueil",
  },
  {
    id: ncNanoId(),
    href: "/articles",
    name: "Articles",
    
  },

  {
    id: ncNanoId(),
    href: "/audios",
    name: "Audios",
  },
  {
    id: ncNanoId(),
    href: "/ebooks",
    name: "Ebooks",
  },
  {
    id: ncNanoId(),
    href: "/images",
    name: "Images",
  },
  {
    id: ncNanoId(),
    href: "/videos",
    name: "Vidéos",
  },
];
