import { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import NcImage from "components/NcImage/NcImage";
import axios from "axios";
import ReactPaginate from "react-paginate";
import articleCover from "images/insta.jpg";
import Spinner from "UI/Spinner";
import InstagramGlide from "./InstagramGlide";
const URL_API = process.env.REACT_APP_API;

export interface PageArchiveProps {
  className?: string;
}

const PageArchive: FC<PageArchiveProps> = ({ className = "" }) => {
  const [page, setPage] = useState<any>(0);
  const [images, setImages] = useState<any>();
  const [isLoading, setIsLoading] = useState(true);

  // GET ARTICLES BY CATEGORIE
  useEffect(() => {
    setIsLoading(true);
    axios
      .get(
        `${URL_API}publications-instagrams?populate=lienImage&pagination[page]=${page}&pagination[pageSize]=16&sort[0]=sort%3Aasc`
      )
      .then((res) => res.data)
      .then((data) => {
        setImages(data);
        setIsLoading(false);
      });
  }, [page]);

  const pageChangeHandler = (data: any) => {
    setPage(data.selected + 1);
    setIsLoading(true);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const renderPagination = () => {
    return (
      <ReactPaginate
        pageRangeDisplayed={2}
        marginPagesDisplayed={1}
        forcePage={0}
        pageCount={images?.meta ? images.meta.pagination.pageCount : 1}
        onPageChange={pageChangeHandler}
        nextLabel={">"}
        previousLabel={"<"}
        containerClassName={"flex items-center"}
        pageClassName={" flex items-center justify-center rounded-full w-8 h-8"}
        previousClassName={
          "flex items-center justify-center rounded-full w-8 h-8"
        }
        nextLinkClassName={
          " flex items-center justify-center rounded-full w-8 h-8"
        }
        activeClassName={
          "bg-[#12629d] shadow-lg shadow-indigo-500/50 text-secondary-100 hover:text-netral-500"
        }
      />
    );
  };

  return (
    <div
      className={`nc-PageArchive overflow-hidden ${className}`}
      data-nc-id="PageArchive"
    >
      <Helmet>
        <title>An-Nassiha || Images</title>
      </Helmet>

      <div className="w-full px-2 xl:max-w-screen-2xl mx-auto">
        <div className="rounded-3xl relative aspect-w-16 aspect-h-6 overflow-hidden">
          <NcImage
            containerClassName="absolute inset-0"
            src={articleCover}
            className="object-cover h-full"
          />
          <div className="absolute inset-0 bg-black text-white bg-opacity-30 flex flex-col items-center justify-center">
            <h2 className="inline-block align-middle text-5xl font-semibold md:text-7xl ">
              {"Images"}
            </h2>
          </div>
        </div>

        <div className="py-12 lg:py-[3rem] space-y-12 lg:space-y-12">
          {/* LOOP ITEMS */}
          {!isLoading ? (
            <div className="grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 md:gap-8 mt-8 lg:mt-10">
              {images?.data &&
                images.data.map((post: any) => {
                  return (
                    <div key={post.id} className="aspect-[1/1]">
                      {/* eslint-disable-next-line react/jsx-no-target-blank */}
                      <InstagramGlide post={post} />
                    </div>
                  );
                })}
            </div>
          ) : (
            <Spinner />
          )}

          {/* PAGINATIONS */}
          <div className="flex flex-col mt-12 lg:mt-16 space-y-5 sm:space-y-0 sm:space-x-3 sm:flex-row sm:justify-between sm:items-center">
            {images?.meta.pagination.pageCount > 1 && renderPagination()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageArchive;
