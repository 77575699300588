import { FC, useEffect, useState } from "react";
// import ModalCategories from "./ModalCategories";
import { Helmet } from "react-helmet";
import NcImage from "components/NcImage/NcImage";
import { useParams } from "react-router-dom";
import axios from "axios";
import ReactPaginate from "react-paginate";
import Spinner from "UI/Spinner";
import Nav from "components/Nav/Nav";
import NavItem from "components/NavItem/NavItem";
import Card10V2 from "components/Card10/Card10V2";
import NoDataSVG from "./NoDataSVG";
import Input from "components/Input/Input";
import imageHolder from "images/items.jpeg";
import InstagramGlide from "components/Instagram/InstagramGlide";
import { checkCategories } from "./filter";
import styles from "./PageArchiveCategories.module.css";
const URL_API = process.env.REACT_APP_API;

export interface PageArchiveProps {
  className?: string;
}

const PageArchive: FC<PageArchiveProps> = ({ className = "" }) => {
  const [catTabs, setCatTabs] = useState<any>([]);
  const [tabActive, setTabActive] = useState<string>(catTabs[0]);
  const { slug } = useParams<any>();
  const [searchInput, setSearchInput] = useState<any>("");
  const [inputHandler, setInputHandler] = useState<any>("");
  const [posts, setPosts] = useState<any>([]);
  const [page, setPage] = useState<any>(0);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const navItems = checkCategories(slug)
      .then((data) => {
        return data.map((item: any) => {
          return [
            item?.attributes?.articles.data.length > 0 ? "Articles" : "",
            item?.attributes?.collection_audios.data.length > 0 ? "Audios" : "",
            item?.attributes?.ebooks.data.length > 0 ? "Ebooks" : "",
            item?.attributes?.videos.data.length > 0 ? "Vidéos" : "",
          ];
        });
      })
      .then((nav: any) => nav);
    const filtredItems = navItems.then((items: any) =>
      items[0].filter((item: any) => item !== "")
    );
    filtredItems.then((cat) => setCatTabs(cat));
  }, []);

  useEffect(() => {
    setTabActive(catTabs[0]);
  }, [catTabs]);

  const handleClickTab = (item: string) => {
    if (item === tabActive) {
      return;
    }
    setTabActive(item);
    getDataByCategorie(item);
    setPage(0);
  };

  useEffect(() => {
    setIsLoading(true);
    getDataByCategorie(tabActive);
  }, [page, inputHandler, searchInput, tabActive]);

  const getDataByCategorie = (item: any) => {
    switch (item) {
      case "Articles":
        axios
          .get(
            `${URL_API}articles?populate=lienImage,categories&filters[categories][slug][$in][0]=${slug}&filters[titre][$containsi]=${
              searchInput || inputHandler
            }&pagination[page]=${page}&pagination[pageSize]=16`
          )
          .then((res) => res.data)
          .then((data) => {
            setPosts(data);
            setIsLoading(false);
          })
          .catch((err) => setPosts({ data: [] }));
        break;

      case "Vidéos":
        axios
          .get(
            `${URL_API}videos?populate=lienImage&filters[categories][slug][$in][0]=${slug}&filters[titre][$containsi]=${
              searchInput || inputHandler
            }&pagination[page]=${page}&pagination[pageSize]=16`
          )
          .then((res) => res.data)
          .then((data) => {
            setPosts(data);
            setIsLoading(false);
          })
          .catch((err) => setPosts({ data: [] }));
        break;

      case "Audios":
        axios
          .get(
            `${URL_API}collection-audios?populate=lienImage&filters[categories][slug][$in][0]=${slug}&filters[titre][$containsi]=${
              searchInput || inputHandler
            }&pagination[page]=${page}&pagination[pageSize]=16`
          )
          .then((res) => res.data)
          .then((data) => {
            setPosts(data);
            setIsLoading(false);
          })
          .catch((err) => setPosts({ data: [] }));
        break;

      case "Ebooks":
        axios
          .get(
            `${URL_API}ebooks?populate=lienImage&filters[categories][slug][$in][0]=${slug}&filters[titre][$containsi]=${
              searchInput || inputHandler
            }&pagination[page]=${page}&pagination[pageSize]=16`
          )
          .then((res) => res.data)
          .then((data) => {
            setPosts(data);
            setIsLoading(false);
          })
          .catch((err) => setPosts({ data: [] }));
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    getDataByCategorie(tabActive);
  }, [searchInput, inputHandler, tabActive]);

  let pageNumber = posts?.meta ? posts.meta.pagination.pageCount : 1;

  const pageChangeHandler = (data: any) => {
    setPage(data.selected + 1);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const renderPagination = () => {
    return (
      <ReactPaginate
        forcePage={0}
        pageCount={pageNumber}
        onPageChange={pageChangeHandler}
        nextLabel={">"}
        previousLabel={"<"}
        containerClassName={"flex items-center"}
        pageClassName={" flex items-center justify-center rounded-full w-8 h-8"}
        previousClassName={
          "flex items-center justify-center rounded-full w-8 h-8"
        }
        nextLinkClassName={
          " flex items-center justify-center rounded-full w-8 h-8"
        }
        activeClassName={
          "bg-[#12629d] shadow-lg shadow-indigo-500/50 text-secondary-100 hover:text-netral-500"
        }
      />
    );
  };

  const onChangeHandler = (e: any) => {
    let timer = setTimeout(() => setSearchInput(e.target?.value), 2000);
    setIsLoading(true);
    return () => clearTimeout(timer);
  };
  const searchHandler = () => {
    setInputHandler(searchInput);
  };

  return (
    <div
      className={`nc-PageArchive overflow-hidden ${className}`}
      data-nc-id="PageArchive"
    >
      <Helmet>
        <title>An-Nassiha</title>
      </Helmet>

      {!tabActive ? (
        <Spinner />
      ) : (
        <>
          <div className="w-full px-2 xl:max-w-screen-2xl mx-auto">
            <div className="rounded-3xl relative aspect-w-16 aspect-h-6 overflow-hidden">
              <NcImage
                containerClassName="absolute inset-0"
                src={imageHolder}
                className="object-cover w-full h-full"
              />
              <div className="absolute inset-0 bg-black text-white bg-opacity-30 flex flex-col items-center justify-center">
                <h2
                  className={`inline-block align-middle font-semibold ${styles.title} `}
                >
                  {slug && slug.replaceAll("-", " ").toUpperCase()}
                </h2>
              </div>
            </div>
            {/* {(!posts?.data || !posts) && <Spinner />} */}
            {posts?.data && (
              <div
              // className="py-16 lg:py-28 space-y-16 lg:space-y-28"
              >
                <div>
                  <div className="flex my-10 justify-between xl:flex-row lg:flex-row flex-col sm:items-center sm:justify-between gap-4">
                    <Nav className="sm:space-x-2">
                      {catTabs.map((item: any, index: any) => (
                        <NavItem
                          key={index}
                          isActive={tabActive === item}
                          onClick={() => handleClickTab(item)}
                        >
                          {item}
                        </NavItem>
                      ))}
                    </Nav>
                    <div className="xl:w-96 lg:w-96 w-full relative">
                      <Input
                        onChange={onChangeHandler}
                        className="w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 rounded-3xl"
                      />
                      <button
                        onClick={searchHandler}
                        className="absolute top-1/2 right-4 -translate-y-1/2"
                        type="submit"
                      >
                        <i className={"las la-search fa-xl "}></i>
                      </button>
                    </div>
                  </div>

                  {/* LOOP ITEMS */}
                  {!isLoading ? (
                    <div className="grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 md:gap-8 mt-8 lg:mt-10">
                      {posts?.data &&
                        !posts?.dataType &&
                        posts.data.map((post: any) => (
                          <Card10V2 key={post.id} post={post} />
                        ))}

                      {posts?.data &&
                        posts?.dataType === "Vidéos" &&
                        posts.data.map((post: any) => (
                          <Card10V2 key={post.id} post={post} />
                        ))}

                      {posts?.data &&
                        posts?.dataType === "Audios" &&
                        posts.data.map((post: any) => (
                          <Card10V2 key={post.id} post={post} />
                        ))}

                      {posts?.data &&
                        posts?.dataType === "Ebooks" &&
                        posts.data.map((post: any) => (
                          <Card10V2 key={post.id} post={post} />
                        ))}

                      {(posts?.data.length === 0 ||
                        !posts?.data ||
                        posts.length === 0) && <NoDataSVG />}
                    </div>
                  ) : (
                    <Spinner />
                  )}

                  {/* PAGINATIONS */}
                  <div className="flex flex-col my-6 lg:my-6 space-y-5 sm:space-y-0 sm:space-x-3 sm:flex-row sm:justify-between sm:items-center">
                    {posts?.data.length !== 0 &&
                      posts.meta.pagination.pageCount > 1 &&
                      renderPagination()}
                  </div>
                </div>
              </div>
            )}
          </div>
          <InstagramGlide />
        </>
      )}
    </div>
  );
};

export default PageArchive;
