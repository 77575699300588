import { FC } from "react";
import Navigation from "components/Navigation/Navigation";
import SearchDropdown from "./SearchDropdown";
import MenuBar from "components/MenuBar/MenuBar";
import DarkModeContainer from "containers/DarkModeContainer/DarkModeContainer";
import logo from "../../images/logo-annassiha.png";
import logoDark from "../../images/logo-annassiha-dark.png";
import { Link } from "react-router-dom";
import { useAppSelector } from "app/hooks";
import { selectDarkmodeState } from "app/darkmode/darkmode";

export interface MainNav1Props {
  isTop: boolean;
}

const MainNav1: FC<MainNav1Props> = ({ isTop }) => {
  const darkmodeIsEnnable = useAppSelector(selectDarkmodeState);

  return (
    <div
      className={`nc-MainNav1 relative z-10 ${
        isTop ? "onTop " : "notOnTop backdrop-filter"
      }`}
    >
      <div className="container py-5 relative flex justify-between items-center space-x-4 xl:space-x-8">
        <div className="flex justify-start flex-grow items-center space-x-4 sm:space-x-10 2xl:space-x-14">
          <Link to="/" className="w-32">
            <img src={!darkmodeIsEnnable ? logo : logoDark} alt="an-nassiha" />
          </Link>
          <Navigation />
        </div>
        <div className="flex-shrink-0 flex items-center justify-end text-neutral-700 dark:text-neutral-100 space-x-1">
          <div className="hidden items-center xl:flex space-x-1">
            <DarkModeContainer />
            <SearchDropdown />
            <div className="px-1" />
          </div>
          <div className="flex items-center xl:hidden">
            <div className="px-1" />
            <div className="flex gap-2">
              <DarkModeContainer />
              <SearchDropdown />
            </div>
            <MenuBar />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainNav1;
