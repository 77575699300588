import Glide from "@glidejs/glide";
import NextPrev from "components/NextPrev/NextPrev";
import { FC, memo, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ncNanoId from "utils/ncNanoId";
import imageHolder from "images/slide-pardefaut.jpg";
import "./glide.css";

export interface SectionLargeSliderProps {
  className?: string;
  heading?: string;
  posts: any;
}

const SectionLargeSlider: FC<SectionLargeSliderProps> = ({
  posts,
  heading = "Derniers articles",
  className = "",
}) => {
  const UNIQUE_CLASS = ncNanoId("sliderNewCategories_");

  const MY_GLIDE = new Glide(`.${UNIQUE_CLASS}`, {
    perView: 1,
    autoplay: 5000,
    hoverpause: true,
  });

  useEffect(() => {
    if (!MY_GLIDE) return;
    MY_GLIDE.mount();
  }, [MY_GLIDE]);

  return (
    <div className={`nc-SectionLargeSlider ${className}`}>
      <div className={`${UNIQUE_CLASS} relative`}>
        <div className="glide__track" data-glide-el="track">
          <ul className="glide__slides">
            {posts.map((item: any, index: any) => {
              return (
                <li key={index} className={`glide__slide w-3/4`}>
                  <Link to={`/single/${item?.attributes?.slug}`}>
                    <img
                      src={
                        item?.attributes?.lienImage.length > 0
                          ? item?.attributes?.lienImage[0]?.lienImage
                          : imageHolder
                      }
                      alt={item?.attributes?.title}
                      className="object-fit center rounded-3xl"
                    />
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
        <NextPrev containerClassName="center button" />
      </div>
    </div>
  );
};

export default memo(SectionLargeSlider);
