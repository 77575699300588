import { FC, ReactNode, useEffect, useState } from "react";
import { PostDataType, TaxonomyType } from "data/types";
import { useAppDispatch } from "app/hooks";
import { changeCurrentPage } from "app/pages/pages";
import { useParams } from "react-router-dom";
import { join } from "utils/dateFormatter";
import PODCAST_IMAGE from "images/logo-PODCAST.jpg";
import InstagramGlide from "components/Instagram/InstagramGlide";
import PageSingleContent from "./PageSingleContent";
import { Helmet } from "react-helmet-async";
import logoAnnassiha from "images/logo-annassiha.png";

export interface PageSingleGalleryProps {
  className?: string;
}

export interface SinglePageType extends PostDataType {
  tags: TaxonomyType[];
  content: string | ReactNode;
}

const PageSingleGallery: FC<PageSingleGalleryProps> = ({ className = "" }) => {
  const dispatch = useAppDispatch();
  const [SINGLE_DATA, setSingleData] = useState<any>();
  const [SINGLE, setSINGLE] = useState<any>();
  const { slug } = useParams<any>();
  const imgGallery = (imgs: any) => imgs.map((data: any) => data.lienImage);

  // UPDATE CURRENTPAGE DATA IN PAGEREDUCERS
  useEffect(() => {
    dispatch(changeCurrentPage({ type: `/single/:slug`, data: SINGLE }));
    return () => {
      dispatch(changeCurrentPage({ type: "/", data: {} }));
    };
  }, [SINGLE]);

  useEffect(() => {
    if (SINGLE_DATA) {
      const attributes = SINGLE_DATA?.attributes;
      setSINGLE({
        id: SINGLE_DATA?.id,
        featuredImage: PODCAST_IMAGE,
        title: attributes?.titre ? attributes.titre : "",
        source: attributes?.source ? attributes?.source.split(",") : "",
        date: attributes?.publishedAt
          ? join(new Date(attributes.publishedAt), " ")
          : "",
        content: attributes?.contenu ? attributes.contenu : "",
        author:
          attributes?.auteurs?.data.length !== 0
            ? {
                authorName: attributes?.auteurs?.data[0].attributes.titre,
                authorSlug: attributes?.auteurs?.data[0].attributes.slug,
              }
            : "",
        pdf: attributes?.lienEbook ? attributes?.lienEbook : attributes?.lien,
        lienZip: attributes?.lienZip,
        audios: attributes?.audios ? attributes.audios.data : "",
        lienVideo: {
          id: SINGLE_DATA?.id,
          videoUrl: attributes?.lienVideo ? attributes.lienVideo : "",
          postType: "video",
        },
        // relatedTag: attributes?.categories && attributes?.categories.length !== 0 ? attributes?.categories?.data[0]?.attributes?.slug : '',
        // tags: (TAGS !== '' && TAGS.length > 0) ? TAGS.filter((_:any, i:any) => i < 2) : [],
        lienAudio: {
          id: SINGLE_DATA?.id,
          audioUrl: attributes?.lienAudio ? attributes.lienAudio : "",
          title: attributes?.titre ? attributes.titre : "",
          author: attributes?.auteurs?.data[0]?.attributes?.titre,
          postType: "audio",
        },
        gallery:
          attributes?.lienImage?.length > 0
            ? imgGallery(attributes.lienImage)
            : "",
      });
    }
    return () => {};
  }, [SINGLE_DATA]);

  const getDataFromChild = (data: any) => {
    setSingleData(data);
  };

  return (
    <>
      <Helmet>
        <meta property="og:image" content={SINGLE?.title} />
        <meta
          property="og:image"
          content={SINGLE?.gallery !== "" ? SINGLE?.gallery[0] : logoAnnassiha}
        />
        <meta
          property="twitter:image"
          content={SINGLE?.gallery !== "" ? SINGLE?.gallery[0] : logoAnnassiha}
        />
        <meta property="og:url" content={window.location.href} />
        <title>{`An-nassiha - ${SINGLE?.title}`}</title>
      </Helmet>
      <div
        className={`nc-PageSingleGallery pt-8 lg:pt-16 overflow-x-hidden ${className}`}
        data-nc-id="PageSingleGallery"
      >
        <PageSingleContent
          slug={slug}
          SINGLE_DATA={SINGLE}
          getDataFromChild={getDataFromChild}
        />
      </div>
      <InstagramGlide />
    </>
  );
};

export default PageSingleGallery;
