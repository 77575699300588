import { BrowserRouter, Switch, Route } from "react-router-dom";
import { Page } from "./types";
import ScrollToTop from "./ScrollToTop";
import Footer from "components/Footer/Footer";
import Page404 from "containers/Page404/Page404";
import PageArchive from "containers/PageArchive/PageArchive";
import PageArchiveAuthor from "containers/PageArchive/PageArchiveAuthor";
import PageAbout from "containers/PageAbout/PageAbout";
import PageContact from "containers/PageContact/PageContact";
import HeaderContainer from "containers/HeaderContainer/HeaderContainer";
import PageHome from "containers/PageHome/PageHome";
import PageAuthorV2 from "containers/PageAuthor/PageAuthorV2";
import PageSearchV2 from "containers/PageSearch/PageSearchV2";
import MediaRunningContainer from "containers/MediaRunningContainer/MediaRunningContainer";
import PageSingleGallery from "containers/PageSingleGallery/PageSingleGallery";
import PageArchiveVideo from "containers/PageArchive/PageArchiveVideo";
import PageArchiveAudio from "containers/PageArchive/PageArchiveAudio";
import MediaRunningContainerForSafari from "containers/MediaRunningContainer/MediaRunningContainerForSafari";
import isSafariBrowser from "utils/isSafariBrowser";
import PageArchiveCategorie from "containers/PageArchive/PageArchiveCategorie";
import PageArchiveEBooks from "containers/PageArchive/PageArchiveEBooks";
import PageArchiveInstagram from "containers/PageArchive/PageArchiveInstagram";
import PageCategories from "containers/PageCategorie/PageCategories";
import Admin from "containers/Admin/Admin";

export const pages: Page[] = [
  { path: "/", exact: true, component: PageHome },
  { path: "/#", exact: true, component: PageHome },
  { path: "/articles", component: PageArchive },
  { path: "/themes/:slug", component: PageArchiveCategorie },
  { path: "/archive-author", component: PageArchiveAuthor },
  { path: "/videos", component: PageArchiveVideo },
  { path: "/audios", component: PageArchiveAudio },
  { path: "/ebooks", component: PageArchiveEBooks },
  { path: "/themes", component: PageCategories },
  { path: "/images", component: PageArchiveInstagram },
  { path: "/author/:slug", component: PageAuthorV2 },
  { path: "/single/:slug", component: PageSingleGallery },
  { path: "/search/:slug", component: PageSearchV2 },
  { path: "/about", component: PageAbout },
  { path: "/contact", component: PageContact },
  { path: "/page404", component: Page404 }, 
  { path: "/admin", component: Admin },

];

const Routes = () => {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <HeaderContainer />
      <Switch>
        {pages.map(({ component, path, exact }) => {
          return (
            <Route
              key={path}
              component={component}
              exact={!!exact}
              path={path}
            />
          );
        })}
        <Route component={Page404} />
      </Switch>
      <Footer />
      {/* MEDIA */}

      {/* //is Safari on an apple touch-screen device */}
      {isSafariBrowser() ? (
        <MediaRunningContainerForSafari />
      ) : (
        <MediaRunningContainer />
      )}
    </BrowserRouter>
  );
};

export default Routes;
