import { FC } from "react";
import { NavLink } from "react-router-dom";
import NcImage from "components/NcImage/NcImage";
import "./CardAuthorBox2.css";

export interface CardAuthorBox2Props {
  className?: string;
  href?: any;
  item: any;
}

const CardAuthorBox2: FC<CardAuthorBox2Props> = ({
  className = "",
  item,
  href = `/author/${item.attributes.slug}`,
}) => {
  // NEW IMAGE

  const author = {
    id: item.id,
    displayName: item.attributes.titre,
    bgImage:
      item.attributes?.lienImage.length > 0
        ? item.attributes?.lienImage[0].lienImage
        : "",
  };

  return (
    <NavLink
      // to={`/author/${item.attributes.slug}`}
      to={`${href}`}
      className={`nc-CardAuthorBox2 flex flex-col overflow-hidden [ nc-box-has-hover ] [ nc-dark-box-bg-has-hover ] ${className}`}
      data-nc-id="CardAuthorBox2"
    >
      <div className="relative flex-shrink-0">
        <div>
          <NcImage
            containerClassName="flex w-full h-full"
            src={author?.bgImage !== "" && author.bgImage}
            className="aspec-square"
          />
        </div>
      </div>

      <div className="text-center mt-2 mb-5">
        <div className="mt-3">
          <h2 className={`text-base font-medium`}>
            <span className="line-clamp-1">{author.displayName}</span>
          </h2>
        </div>
      </div>
    </NavLink>
  );
};

export default CardAuthorBox2;
