import { FC, useEffect, useState } from "react";

import Nav from "components/Nav/Nav";
import NavItem from "components/NavItem/NavItem";
import Input from "components/Input/Input";
import HeadBackgroundCommon from "components/HeadBackgroundCommon/HeadBackgroundCommon";
import { Helmet } from "react-helmet";
import { getDataByTab, fitlredCategories } from "./getData";
import Card10V2 from "components/Card10/Card10V2";
import Spinner from "UI/Spinner";
import CardAuthorBox2 from "components/CardAuthorBox2/CardAuthorBox2";
import { useHistory, useParams } from "react-router-dom";

export interface PageSearchV2Props {
  className?: string;
}

const PageSearchV2: FC<PageSearchV2Props> = ({ className = "" }) => {
  const [tabs, setTabs] = useState<any>([]);
  const [tabActive, setTabActive] = useState<string>();
  const [data, setData] = useState<any>();
  const [isLoaded, setIsLoaded] = useState<any>(false);
  const [isLoadedNav, setIsLoadedNav] = useState(false);
  const { slug } = useParams<any>();
  const search = useHistory();

  const handleClickTab = (item: string) => {
    if (item === tabActive) {
      return;
    }
    setTabActive(item);
  };

  useEffect(() => {
    setIsLoadedNav(false);
    setIsLoaded(false);
    fitlredCategories(slug)
      .then((res) => res.filter((item) => item !== false))
      .then((items) => {
        setTabs(items);
        setIsLoadedNav(true);
      });
  }, [slug]);

  useEffect(() => {
    setTabActive(tabs[0]);
  }, [tabs, slug]);

  useEffect(() => {
    setIsLoadedNav(false);
    setIsLoaded(false);
    getDataByTab(tabActive, slug)
      ?.then((res) => setData(res))
      .then(() => setIsLoadedNav(true))
      .then(() => setIsLoaded(true));
    console.log("AN-NASSIHA V2");
  }, [tabActive, slug]);

  const submitHandler = (e: any) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    search.push(`/search/${formData.get("search")}`);
  };

  return (
    <div className={`nc-PageSearchV2 ${className}`} data-nc-id="PageSearchV2">
      <HeadBackgroundCommon className="h-24 2xl:h-28" />
      <Helmet>
        <title>An-Nassiha</title>
      </Helmet>
      <div className="container">
        <header className="max-w-2xl mx-auto -mt-10 flex flex-col lg:-mt-7">
          <form className="relative" onSubmit={submitHandler}>
            <label
              htmlFor="search-input"
              className="text-neutral-500 dark:text-neutral-300"
            >
              <Input
                id="search-input"
                name="search"
                type="search"
                placeholder="Tapez votre recherche et cliquez sur Entrer"
                className="shadow-lg rounded-xl border-opacity-0"
                sizeClass="pl-14 py-5 pr-5 md:pl-16"
              />
              <span className="absolute left-5 top-1/2 transform -translate-y-1/2 text-2xl md:left-6">
                <svg width="24" height="24" fill="none" viewBox="0 0 24 24">
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                    d="M19.25 19.25L15.5 15.5M4.75 11C4.75 7.54822 7.54822 4.75 11 4.75C14.4518 4.75 17.25 7.54822 17.25 11C17.25 14.4518 14.4518 17.25 11 17.25C7.54822 17.25 4.75 14.4518 4.75 11Z"
                  ></path>
                </svg>
              </span>
            </label>
          </form>
        </header>
      </div>
      <div className="container py-16 lg:py-28 space-y-16 lg:space-y-28">
        <main>
          {/* TABS FILTER */}
          <div className="flex flex-col sm:items-center sm:justify-between sm:flex-row ">
            {isLoadedNav && (
              <Nav
                containerClassName="w-full overflow-x-auto hiddenScrollbar"
                className=" sm:space-x-2"
              >
                {tabs.map((item: any, index: any) => (
                  <NavItem
                    key={index}
                    isActive={tabActive === item}
                    onClick={() => handleClickTab(item)}
                  >
                    {item}
                  </NavItem>
                ))}
              </Nav>
            )}
            <div className="block my-4 border-b w-full border-neutral-100 sm:hidden"></div>
          </div>
          {isLoaded &&
            isLoadedNav &&
            data &&
            data.length !== 0 &&
            (tabActive === "Articles" ||
              tabActive === "Vidéos" ||
              tabActive === "Audios" ||
              tabActive === "Ebooks") && (
              <div className="grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 md:gap-8 mt-8 lg:mt-10">
                {data &&
                  data.map((post: any) => (
                    <Card10V2 key={post.id} post={post} />
                  ))}
              </div>
            )}

          {isLoaded &&
            isLoadedNav &&
            data &&
            data.length !== 0 &&
            (tabActive === "Auteurs" || tabActive === "Thèmes") && (
              <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-5 sm:gap-6 md:gap-8 mt-8 lg:mt-10">
                {data.map((item: any, i: any) => (
                  <CardAuthorBox2
                    key={i}
                    item={item}
                    href={`/${tabActive === "Thèmes" ? "themes" : "author"}/${
                      item.attributes.slug
                    }`}
                  />
                ))}
              </div>
            )}

          {!isLoaded && <Spinner />}
        </main>
      </div>
    </div>
  );
};

export default PageSearchV2;
