import { FC, useEffect, useState } from "react";
import ModalCategories from "./ModalCategories";
import ArchiveFilterListBox from "components/ArchiveFilterListBox/ArchiveFilterListBox";
import { Helmet } from "react-helmet";
import Card10V2 from "components/Card10/Card10V2";
import axios from "axios";
import ReactPaginate from "react-paginate";
import NcImage from "components/NcImage/NcImage";
import Input from "components/Input/Input";
import imageHolder from 'images/video.jpeg'
import Spinner from "UI/Spinner";
import ModalAuthors from "./ModalAuthors";
import InstagramGlide from "components/Instagram/InstagramGlide";
import styles from './Modal.module.css'
import { filter } from "./filter";
const API_URL = process.env.REACT_APP_API

export interface PageArchiveVideoProps {
  className?: string;
}

const FILTERS = [
  { name: "Récent" },
  { name: "Ancien" },
  { name: "A-Z" },
  { name: "Z-A" },
];

const PageArchiveVideo: FC<PageArchiveVideoProps> = ({ className = "" }) => {

  const [videos, setVideos] = useState<any>([]);
  const [page, setPage] = useState<any>(0); 
  const [searchInput, setSearchInput] = useState<any>('');
  const [inputHandler, setInputHandler] = useState<any>('');
  const [catList, setCatList] = useState<any>([]);
  const [filters, setFilters] = useState<any>([]);
  const [selectedFILTERS, setSelecteFILTERS] = useState({name : 'Récent'});
  const [isLoading, setIsLoading] = useState(true);
  const [authList, setAuthList] = useState<any>([]);
  const [authors, setAuthors] = useState<any>([]);

  useEffect(() => {
    setIsLoading(true)
    axios.get(`${API_URL}videos?populate=*&filters[titre][$containsi]=${searchInput || inputHandler}&pagination[page]=${page}&pagination[pageSize]=16${catList.join('')}${authList.join('')}${filter(selectedFILTERS.name)}`)
    .then(res => res.data)
    .then(data => {
      setVideos(data)
      setIsLoading(false)
    });
  }, [page, filters, inputHandler, searchInput, selectedFILTERS, authors]);

  const pageChangeHandler = (data:any) => {
    setPage(data.selected+1);
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }

  const showArticlesByAuthors = (_:any, titre:any) => {
    setPage(0);
    checkAuthors(titre);
    if(authList.includes(`&filters[auteurs][titre]=${titre}`)){
      const newCatList = authList.filter((item: string) => item !== `&filters[auteurs][titre]=${titre}`);
      setAuthList(newCatList);
    } else {
      setAuthList((prevCat:any) => [...prevCat, `&filters[auteurs][titre]=${titre}`])
    }
  } 
  const checkAuthors = (titre:any) => {
    if(authors.includes(titre)){
      const newFiltres = authors.filter((item: string) => item !== titre);
      setAuthors(newFiltres);
    }else {
      setAuthors((prevFilters:any) => [...prevFilters, titre]);
    }
  }

  const onChangeHandler = (e:any) => {
    let timer = setTimeout(() => setSearchInput(e.target?.value), 2000);
    setIsLoading(true)
    return () => clearTimeout(timer)    
 }
 const searchHandler = () => {
   setInputHandler(searchInput);
 }

 const setSelecet = (item:any) => {
  setSelecteFILTERS(item);
 }
  

  const renderPagination = () => {
    return <ReactPaginate 
      forcePage={0}
      pageCount={videos?.meta ? videos.meta.pagination.pageCount : 1}
      onPageChange={pageChangeHandler}
      nextLabel = {'>'}
      previousLabel= {'<'}
      containerClassName={'flex items-center'}
      pageClassName={' flex items-center justify-center rounded-full w-8 h-8'}
      previousClassName = {'flex items-center justify-center rounded-full w-8 h-8'}
      nextLinkClassName = {' flex items-center justify-center rounded-full w-8 h-8'}
      activeClassName={'bg-[#12629d] shadow-lg shadow-indigo-500/50 text-secondary-100 hover:text-netral-500'}
      />
  }
  
  const showArticlesByCategorie = (_:any, titre:any) => {
    setPage(0);
    checkFilters(titre);
    if(catList.includes(`&filters[categories][titre]=${titre}`)){
      const newCatList = catList.filter((item: string) => item !== `&filters[categories][titre]=${titre}`);
      setCatList(newCatList);
    } else {
      setCatList((prevCat:any) => [...prevCat, `&filters[categories][titre]=${titre}`])
    }
  }  
  
  const checkFilters = (titre:any) => {
    if(filters.includes(titre)){
      const newFiltres = filters.filter((item: string) => item !== titre);
      setFilters(newFiltres);
    }else {
      setFilters((prevFilters:any) => [...prevFilters, titre]);
    }
  }

  const renderSection = () => {
    return (
      <div className="grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 md:gap-8 mt-8 lg:mt-10">
            {videos.data && videos.data.map((p:any) => (
              <Card10V2
                post={p}
                key={p.id}
              />
              
            ))}
      </div>
    );
  };

  return (
    <div
      className={`nc-PageArchiveEbooks overflow-hidden ${className}`}
      data-nc-id="PageArchiveEbooks"
    >
      <Helmet>
        <title>An-Nassiha || Vidéos</title>
      </Helmet>

      {/* HEADER */}
      <div className="w-full px-2 xl:max-w-screen-2xl mx-auto">
        <div className="rounded-3xl relative aspect-w-16 aspect-h-6 overflow-hidden">
          <NcImage
            containerClassName="absolute inset-0"
            src={imageHolder}
            className="object-cover w-full h-full"
          />
          <div className="absolute inset-0 bg-black text-white bg-opacity-30 flex flex-col items-center justify-center">
            <h2 className="drop-shadow-lg inline-block align-middle ml-3 text-5xl font-semibold md:text-7xl ">
              {"Vidéos"}
            </h2>
          </div>
        </div>

        
        <div>
        <div className=" py-12 lg:py-[3rem] space-y-12 lg:space-y-12">
        <div className="flex justify-between xl:flex-row lg:flex-row flex-col sm:items-center sm:justify-between xl:gap-24 lg:gap-16 gap-4">
            <div className={`${styles.modals} w-full`}>
              <ModalCategories 
                onSendData={showArticlesByCategorie}
                filters={filters}/>
              <ModalAuthors
                onSendData={showArticlesByAuthors}
                filters={authors}/>
            </div>
            <div className={`${styles.modals} w-full`}>
              <ArchiveFilterListBox 
                  lists={FILTERS} 
                  setSelecet={setSelecet}/>
              <div className="relative">
                <Input onChange={onChangeHandler} className="w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 rounded-3xl"/>
                <button onClick={searchHandler} className="absolute top-1/2 right-4 -translate-y-1/2" type="submit"><i className={"las la-search fa-xl "}></i></button>  
              </div>
            </div>
          </div>

          {/* LOOP ITEMS */}
          {isLoading ? <Spinner/> :renderSection()}
          {/* PAGINATIONS */}
          <div className="flex flex-col mt-12 lg:mt-16 space-y-5 sm:space-y-0 sm:space-x-3 sm:flex-row sm:justify-between sm:items-center">
            { videos?.meta && videos?.meta.pagination.pageCount > 1 && renderPagination()}
          </div>
        </div>
      </div>
      </div>
      {/* ====================== END HEADER ====================== */}

      <InstagramGlide />
    </div>
  );

};

export default PageArchiveVideo;
