import axios from "axios"
const URL_API = process.env.REACT_APP_API;


const getCategories = (searchInput) => {
    return axios.get(`${URL_API}categories?filters[titre][$containsi]=${searchInput}&populate=*`)
    .then(res => res.data.data)
}

const getArticles = (searchInput) => {
    return axios.get(`${URL_API}articles?filters[titre][$containsi]=${searchInput}&populate=*`)
    .then(res => res.data.data)
}

const getAudios = (searchInput) => {
    return axios.get(`${URL_API}collection-audios?filters[titre][$containsi]=${searchInput}&populate=*`)
    .then(res => res.data.data)
}

const getEbooks = (searchInput) => {
    return axios.get(`${URL_API}ebooks?filters[titre][$containsi]=${searchInput}&populate=*`)
    .then(res => res.data.data)
}

const getVideos = (searchInput) => {
    return axios.get(`${URL_API}videos?filters[titre][$containsi]=${searchInput}&populate=*`)
    .then(res => res.data.data)
}

const getAuthors = (searchInput) => {
    return axios.get(`${URL_API}auteurs?filters[titre][$containsi]=${searchInput}&populate=*`)
    .then(res => res.data.data)
}

export const getDataByTab = (activeTab, searchInput) => {
    switch (activeTab) {
        case 'Articles':
            return getArticles(searchInput);
        case 'Audios':
            return getAudios(searchInput);
        case 'Ebooks':
            return getEbooks(searchInput);
        case 'Vidéos':
            return getVideos(searchInput);
        case 'Auteurs':
            return getAuthors(searchInput);
        case 'Thèmes':
            return getCategories(searchInput);
        default:
            break;
    }
}

export const fitlredCategories = (search) => {
    return Promise.all([
        getArticles(search),
        getAudios(search),
        getEbooks(search),
        getVideos(search),
        getAuthors(search),
        getCategories(search)
    ]).then(res => {
        return [
            res[0].length !== 0 && 'Articles',
            res[1].length !== 0 && 'Audios',
            res[2].length !== 0 && 'Ebooks',
            res[3].length !== 0 && 'Vidéos',
            res[4].length !== 0 && 'Auteurs',
            res[5].length !== 0 && 'Thèmes',
        ]
    })
}