import Glide from '@glidejs/glide';
import { useEffect } from 'react';
import ncNanoId from 'utils/ncNanoId';
import style from './Modal.module.css';

function InstagramGlide(props: any) {
  const UNIQUE_CLASS = ncNanoId('galleryInstagram_');
  const MY_GLIDE = new Glide(`.${UNIQUE_CLASS}`, {
    // @ts-ignore
    direction:
      document.querySelector('html')?.getAttribute('dir') === 'rtl'
        ? 'rtl'
        : 'ltr',
    gap: 0,
    perView: 1,
  });

  useEffect(() => {
    if (!MY_GLIDE) return;
    MY_GLIDE.mount();
  }, [MY_GLIDE]);

  return (
    <div className={`${UNIQUE_CLASS} group relative z-10 w-full h-full`}>
      <div className='glide__track h-full' data-glide-el='track'>
        <ul className='glide__slides h-full'>
          {props?.post?.attributes?.lienImage.map((item: any, index: any) => {
            return (
              <li className='glide__slide h-full' key={index}>
                <a href={props?.post?.attributes?.lien} target='_blank'>
                  <img
                    src={item?.lienImage}
                    className='rounded-lg object-fill'
                  />
                </a>
              </li>
            );
          })}
        </ul>
        {
          <div className='glide__bullets' data-glide-el='controls[nav]'>
            <>
              {props?.post?.attributes?.lienImage.map((_: any, i: any) => (
                <button
                  className={`glide__bullet ${style.bullets}`}
                  data-glide-dir={`=${i}`}
                ></button>
              ))}
            </>
          </div>
        }
      </div>
    </div>
  );
}

export default InstagramGlide;
