import { FC, ReactNode } from "react";
import { Link } from "react-router-dom";

export interface SectionHeroProps {
  className?: string;
  heading: ReactNode;
  subHeading: string;
  btnText: string;
}

const SectionHero: FC<SectionHeroProps> = ({
  className = "",
  heading,
}) => {
  return (
    <div
      className={`nc-SectionHero relative ${className}`}
      data-nc-id="SectionHero"
    >
        <div className="w-screen container">
          <h2 className="text-3xl !leading-tight font-semibold text-neutral-900 md:text-4xl xl:text-5xl dark:text-neutral-100">
            {heading}
          </h2>
          <div className="flex flex-col gap-8">
            <h2 className=" mt-8 block text-center text-2xl text-neutral-6000 dark:text-neutral-400">
            بسم الله الرحمن الرحيم  
            </h2>

            <p className="text-justify">Louanges à Allah, Seigneur de l’univers. Que la prière et le salut d’Allah soient sur notre Prophète Muhammad ainsi que sur sa famille et l’ensemble de ses compagnons. Ceci dit : <br /><br />

            Le site <Link to="/" className="font-bold underline">an-nassiha.com</Link>  a pour but de <b>faciliter</b> aux francophones <b>l'accès aux sciences islamiques</b>puisées du Coran et de la Sunna selon la compréhension des pieux prédécesseurs par le biais de cours audios, ainsi que de traductions de paroles profitables des savants de la Sunna, tirées de leurs cours, leurs livres ou leurs avis juridiques présentés sous divers supports (articles, ebooks, images, vidéos). <br /><br />

            Les cours proposés sont donnés par <b>Aboubakr Abou Abdillah</b> et <b>Abdullah Abou Ibrahim</b>, qu'Allah les préserve, qui ont tous deux étudié plusieurs années au centre <b>Dar Al Hadith</b> de Shihr au <b>Yémen</b>. <br /><br />
            
            Au cours de ces années, ils ont pu - par la grâce d'Allah - étudier un certain nombre de livres dans différentes matières, comme la croyance, la jurisprudence et autres, avec plusieurs enseignants du centre, et parmi eux, leur noble <b>Cheikh Fahd Ibn Salim As-Soulaymani Al-Adani</b>, qu'Allah lui fasse miséricorde et l'élève en degrés au Paradis. <br /><br />
          
            Ce dernier leur recommanda d'enseigner ce qu'Allah leur a permis d'apprendre afin d'en faire profiter leurs frères francophones et il les encouragea à continuer leurs efforts dans les études et le prêche. <br /><br />
            
            Les autres supports (articles, ebooks, images et vidéos) proposés sur le site sont réalisés <b>sous la supervision</b> de Abdullah Abou Ibrahim. <br /><br />
          
            La perfection appartenant à Allah, n’hésitez pas à nous faire part de vos remarques ou vos suggestions via le formulaire de contact. <br /><br />

            Nous demandons à Allah de nous accorder la réussite et la rectitude ainsi que la sincérité dans les paroles et les actes. Nous Lui demandons également de rendre ce site bénéfique pour l’Islam et les musulmans. Il est certes Celui qui répond aux invocations. <br /><br />

            Que la prière et le salut d’Allah soient sur notre Prophète Muhammad ainsi que sur sa famille et l’ensemble de ses compagnons. Et notre dernière invocation est louange à Allah, Seigneur de l’univers.</p>
          </div>
          
        </div>
      </div>
  );
};

export default SectionHero;
