import { FC, useEffect, useState } from "react";
import WidgetHeading1 from "components/WidgetHeading1/WidgetHeading1";
import axios from "axios";
import { Link } from "react-router-dom";
import imagePlaceHolder from "images/placeholder-small.png";
const URL_API = process.env.REACT_APP_API;

export interface WidgetTagsProps {
  className?: string;
  tags?: any;
}

const WidgetTags: FC<WidgetTagsProps> = ({
  className = "bg-neutral-100 dark:bg-neutral-800",
  tags,
}) => {
  const [TAGS, setTAGS] = useState<any>();

  useEffect(() => {
    //GET CATEGORIES
    axios
      .get(
        `${URL_API}categories?populate=lienImage&sort[0]=sort%3Aasc&pagination[limit]=9`
      )
      .then((res) => res.data)
      .then((data) => {
        const tags = data.data.map((item: any) => {
          return {
            name: item.attributes.titre,
            slug: item.attributes.slug,
            image: item?.attributes?.lienImage,
          };
        });
        setTAGS(tags);
      });
  }, []);

  return (
    <>
      {TAGS && (
        <div
          className={`nc-WidgetTags rounded-3xl overflow-hidden ${className}`}
          data-nc-id="WidgetTags"
        >
          <WidgetHeading1
            title="Thèmes"
            viewAll={{ label: "Afficher Tout", href: "/themes" }}
          />
          <div className="grid grid-cols-3 p-4 xl:p-5 gap-4 ">
            {TAGS.map((tag: any, i: any) => (
              <Link
                to={`/themes/${tag.slug}`}
                key={i}
                className={
                  "nc-CardCategory1 flex items-center relative px-4 py-2 rounded-lg hover:bg-neutral-200 dark:hover:bg-neutral-700"
                }
                data-nc-id="CardCategory1"
              >
                <img
                  src={
                    tag?.image && tag?.image[0].lienImage
                      ? tag?.image[0].lienImage
                      : imagePlaceHolder
                  }
                  alt={tag?.name}
                  loading="lazy"
                  className="rounded-lg"
                />
              </Link>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default WidgetTags;
