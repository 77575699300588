import axios from "axios";
const URL_API = process.env.REACT_APP_API;

export const getData = (slug, cat) => {
  try {
    //GET ARTICLES
    return axios
      .get(`${URL_API}${cat}?filters[slug][$eq]=${slug}&populate=*`)
      .then((res) => res.data)
      .then((data) => data.data);
  } catch (err) {
    console.log(err);
  }
};

export const getRelatedArticles = (theme) => {
  try {
    //GET ARTICLES
    return axios
      .get(
        `${URL_API}categories?filters[slug][$eq]=${theme}&populate=articles.lienImage,collection_audios.lienImage,ebooks.lienImage,videos.lienImage`
      )
      .then((res) => res.data)
      .then((data) => data.data);
  } catch (err) {
    console.log(err);
  }
};
